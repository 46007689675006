import { Draft } from "immer";
import { ImmerReducer } from "use-immer";
import { State, Action } from "../../Store";
import { actionMap } from "./actionMap";

export const reducer: ImmerReducer<State, Action> = (draft: Draft<State>, action: Action) => {
  const { type, payload } = action;

  const mappedAction = actionMap[type];

  if (mappedAction) {
    return mappedAction(draft, payload);
  } else {
    throw Error('Unknown action: ' + type);
  }
};
