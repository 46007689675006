import { AutoThemeProvider, Tab, Tabs } from '@platform-ui/design-system';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import GenericTable from '../Tables/components/GenericTable';
import { APPDOWNLOADS_TABLE_PROPS, HISTORIES_TABLE_PROPS } from '../Tables/constants';
import * as ReactDOM from 'react-dom/client';

const AppDownloadsView = (props) => {

    const [tabs, setTabs] = useState(0);

    const root = ReactDOM.createRoot(document.getElementById("pageHeader"));
    root.render(
        <AutoThemeProvider>
        <h2>{"App Downloads"}</h2>
        <Tabs
                    a11yLabel="tab"
                    value={tabs}
                    dsOnChange={(_, newIndex) => {
                        if (newIndex === 0) {
                            setTabs(0);
                        }
                        if (newIndex === 1) {
                            setTabs(1);
                        }
                        }}
            
                    e2e="appdownloads-tabs"
                    >
                    <Tab
                        id="appdownloads-tab"
                        e2e="appdownloads"
                        label="AppDownloads"
                    />
                    if (typeof props.tables.histories_table != "undefined") {
                        <Tab
                            id="history-tab"
                            e2e="history"
                            label="History"
                        />
                    }
                    
                    
                </Tabs>
                </AutoThemeProvider>
      );

    return (
        <AutoThemeProvider>
            {tabs === 0 && <GenericTable {...APPDOWNLOADS_TABLE_PROPS} {...props.tables.appdownloads_table}/>}
            {tabs === 1 && <GenericTable {...HISTORIES_TABLE_PROPS} {...props.tables.histories_table}/>}
        </AutoThemeProvider>
    )
}

AppDownloadsView.propTypes = {
    tables: PropTypes.object
}

export default AppDownloadsView;