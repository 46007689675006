import { AutoThemeProvider } from '@platform-ui/design-system';
import React, { useState } from 'react';
import GridView from '../GridView/GridView';
import { TENANT_LOGINS_FILTERS, TENANT_LOGINS_FILTER_KEYS } from '../GridView/constants';

function TenantLoginsView(props: any) {
  const [tabs, setTabs] = useState(0);

  return (
    <AutoThemeProvider>
      {tabs === 0 && <GridView filters={TENANT_LOGINS_FILTERS} filterKeys={TENANT_LOGINS_FILTER_KEYS} display={24} {...props} />}
    </AutoThemeProvider>
  )
}

export default TenantLoginsView