import { AutoThemeProvider, Tab, Tabs } from '@platform-ui/design-system';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import * as ReactDOM from 'react-dom/client';
import GenericTable from '../Tables/components/GenericTable';
import { TASKS_TABLE_PROPS } from '../Tables/constants';
import { useFlipperHook } from '../Tables/helpers';

const TenantView = (props) => {

  const [tabs, setTabs] = useState(0);
  const [dataConnectorHubEnabled, _] = useFlipperHook('data_connector_hub_access');
  const [integrationAppsAccess, __] = useFlipperHook('integration_apps_access');

  const root = ReactDOM.createRoot(document.getElementById("pageHeader"));
  var prefix = ""
  if (window.location.href.includes('admin')) {
    prefix = "/admin"
  }

  root.render(
    <AutoThemeProvider>
      <h2>{props.tenant.tenant_name} - {props.tenant.tenant_id}</h2>
      <Tabs
        a11yLabel="tab"
        value={tabs}
        dsOnChange={(_, newIndex) => {
          if (newIndex === 0) {
            setTabs(0);
          }
          if (newIndex === 1) {
            setTabs(1);
          }
        }}
        e2e="tenant-tabs"
      >
        <Tab
          id="tenant-tab-1"
          e2e="tools"
          label="Tools"
        />
        <Tab
          id="tenant-tab-2"
          e2e="apps"
          label="Applications"
        />
        {dataConnectorHubEnabled && <Tab
          href={prefix + "/tools/connectors/" + props.tenant.id}
          label="Integration Hub"
        />}
        {
          integrationAppsAccess && (
            <Tab
              href={
                `${!window.location.host.includes('connect') && !window.location.host.includes('localhost') ? '/services/connect' : ''}${prefix === '/admin' ? prefix : ''}/integration_apps/${props.tenant.id}/integration_apps`
              }
              label="Integration Apps"
            />
          )
        }
      </Tabs>
    </AutoThemeProvider>
  );

  return (
    <AutoThemeProvider>
      {tabs === 0 && <GenericTable {...TASKS_TABLE_PROPS} {...props.tables.tools_table} />}
      {tabs === 1 && <GenericTable {...TASKS_TABLE_PROPS} {...props.tables.apps_table} />}
    </AutoThemeProvider>
  )
}

TenantView.propTypes = {
  tenant: PropTypes.object
}

export default TenantView;