import { AutoThemeProvider, Tab, Tabs, Spinner } from "@platform-ui/design-system";
import React, { useState } from "react";
import * as ReactDOM from 'react-dom/client';
import Flipper from "../Connect/Flipper";
import GridView from "../GridView/GridView";
import Marketplace from "./Marketplace";
import MyConnectors from "./MyConnectors";
import { AVAILABLE_CONNECTORS_QUERY_PARAMS, MY_CONNECTORS_QUERY_PARAMS } from '../GridView/constants'
import { useFlipperHook } from '../Tables/helpers';

function ConnectorsView(props: any) {
  const [tabs, setTabs] = useState(0);
  const root = ReactDOM.createRoot(document.getElementById("pageHeader"));

  const [demo_tenant_enabled, loading] = useFlipperHook('demo_tenant');

  // NOTE(Xander): Revenue needs to grab the url from the iframe level, so we set it here so it can be found by the config wizard
  localStorage.setItem('data-connector-hub-url', window.location.href);

    
    root.render(
        <AutoThemeProvider>
            <h2>{"Integration Hub"}</h2>
            {/* Tabs - Marketplace, My Connectors */}
            <Tabs
                a11yLabel="tab"
                value={tabs}
                dsOnChange={
                    (_, newIndex) => {
                        if (newIndex === 0) {
                            setTabs(0);
                        }
                        if (newIndex === 1) {
                            setTabs(1);
                        }
                    }
                }
                e2e="connectors-tabs"
                >
                    <Tab
                        id="markeplace-tab"
                        e2e="markeplace"
                        label="Marketplace" 
                        aria-controls={""}
                    />
                    <Tab
                        id="my-connectors-tab"
                        e2e="myConnectors"
                        label="My Connectors"
                        aria-controls={""}
                    />
            </Tabs>
        </AutoThemeProvider>
      );

    return (
        <AutoThemeProvider>
            {loading ? <Spinner /> : !demo_tenant_enabled
                ? (
                  <>
                      {tabs === 0 && <GridView backendUri={props.backendMarketplaceUri} gridTitle='All Available Data Connectors' searchEnabled={false} connectorParams={AVAILABLE_CONNECTORS_QUERY_PARAMS}/>}
                      {tabs === 1 && <GridView backendUri={props.backendMyConnectorsUri} gridTitle='Currently Active' searchEnabled={false} connectorParams={MY_CONNECTORS_QUERY_PARAMS}/>}
                  </>
              )
                : (
                  <>
                  {tabs === 0 && <Marketplace {...props} />}
                  {tabs === 1 && <MyConnectors {...props} />}
              </>
                )
            }
        </AutoThemeProvider>
    );
}
export default ConnectorsView;