import { Button } from "@platform-ui/design-system"
import React, { useState } from "react"
import Connect from '../Connect/Connect';

function WorkflowButton(props: any) {
  const [disabled, setDisabled] = useState(props.workflow.triggerUrl ? false : true);

  const workflowCallout = event => {
    setDisabled(true)
    fetch(props.workflow.triggerUrl)
    .then((response) => {
      response.json()
    })
    .catch((error) => {
      Connect.log(error);
    });
  }

  return (
    <Button dsOnClick={workflowCallout} disabled={disabled}>{props.workflow.sourceWorkflowName}</Button>
  )
}
export default WorkflowButton