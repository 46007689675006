import { Accordion, Alert, AutoThemeProvider, Card, Chip, Grid } from "@platform-ui/design-system";
import React from "react";
import SolutionInstanceTile from "./SolutionInstanceTile";
import { useFlipperHook } from '../Tables/helpers';
import { Box } from '@mui/material';
import Connect from "../Connect/Connect";

function Marketplace(props: any) {

  let zuoraImageStyling = {'paddingTop':'20px','paddingBottom':'25px'}
  const [demo_tenant_enabled, loading] = useFlipperHook('demo_tenant');

  return (
    <AutoThemeProvider>
      {demo_tenant_enabled && <Accordion header="New Connectors" defaultExpanded body={
        <Grid container>
          <Grid item xl={2} lg={2} md={4} sm={5} xs={5}>
            <Box onClick={(event)=>{window.location.href = window.location.href.slice(0, window.location.href.lastIndexOf('/')) + '/sfdc'}} style={{cursor:'pointer'}}>
              <Card
                variant="outlined"
                id={"12"} 
                header={<img src={Connect.setImagePath("Salesforce")} height="75px" width="150px" style={(Connect.setImagePath("Salesforce").includes('/Zuora-logo.png') ? zuoraImageStyling : {})}/>}
                body={<div><div style={{height:'39.094px', overflow:'hidden', textOverflow:'ellipsis'}}><h3>{"Salesforce Connector for CRM"}</h3></div><Chip label={"New"} state={'default'} /></div>}
              />
            </Box>
          </Grid>
        </Grid>
      }/>}
    </AutoThemeProvider>
  );
}

export default Marketplace;
