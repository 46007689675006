import { initialState, IntegrationAppForm, IntegrationAppFormContextProvider } from ".";
import React from 'react';

interface IntegrationAppsFormViewProps {
  locales: string[];
  path: string;
  state?: string;
  indexPath: string;
  icon: string;
}

export const IntegrationAppFormView = ({
  locales,
  path,
  state,
  indexPath,
  icon
}: IntegrationAppsFormViewProps) => {
  let parsedState = state ? JSON.parse(state) : initialState


  // NOTE(Xander): state only exists on the edit page, so this block will be skipped on new apps
  // TODO(Xander): This should all really live in the integration_app model, *but* ZKO
  if (state) {
    if (!parsedState.title_translations) {
      parsedState.title_translations = initialState.title_translations
    }
  
    if (!parsedState.description_translations) {
      parsedState.description_translations = initialState.description_translations
    }
  
    if (!parsedState.service_data) {
      parsedState.service_data = initialState.service_data
    }
  
    if (!parsedState.status) {
      parsedState.status = initialState.status
    }

    if (!parsedState.redirect_url) {
      parsedState.redirect_url = initialState.redirect_url
    }
  
    if (!parsedState.meta) {
      parsedState.meta = initialState.meta
    } else {
      if (!parsedState.meta['product']) {
        parsedState.meta['product'] = initialState.meta['product']
      }

      if (!parsedState.meta['category']) {
        parsedState.meta['category'] = initialState.meta['category']
      }
    }
  
    if (!parsedState.ui_layout) {
      parsedState.ui_layout = initialState.ui_layout
    } 
    // NOTE(Xander): Disabling this block til I can go over it again - it currently replaces custom tabs with the default ones
    // else {
    //   if (parsedState.ui_layout['tabs']) {
    //     parsedState.ui_layout = {
    //       tabs: initialState.ui_layout['tabs'].map(defaultTab => {
    //         const existingItem = parsedState.ui_layout['tabs'].find(tab => Object.keys(tab)[0] === Object.keys(defaultTab)[0]);
    //         if (existingItem) {
    //           const tabName = Object.keys(existingItem)[0]
    //           if (!existingItem[tabName]['descriptionTranslations']) {
    //             existingItem[tabName]['descriptionTranslations'] = {
    //               en: {
    //                 tabName: toTitle(tabName)
    //               }
    //             }
    //           }
    //           if (!existingItem[tabName]['descriptionTranslations']) {
    //             existingItem[tabName]['descriptionTranslations'] = {
    //               en: {
    //                 description: ''
    //               }
    //             }
    //           }
    //         }
    //         return existingItem || defaultTab;
    //       })
    //     }
    //   } else {
    //     parsedState.ui_layout = initialState.ui_layout
    //   }
    // }
  }

  if (!parsedState.icon) {
    parsedState.icon = icon
  }

  return (
    <IntegrationAppFormContextProvider initialState={parsedState}>
      <IntegrationAppForm locales={locales} path={path} indexPath={indexPath} />
    </IntegrationAppFormContextProvider>
  )
}

export default IntegrationAppFormView
