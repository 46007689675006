import { Alert, AutoThemeProvider, Table, Spinner } from '@platform-ui/design-system';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser'; 
import $ from 'jquery';

const ApplicationBuildsTable = (props) => {
    // Table Consts
    const [rows, setRows] = useState([])
    const [tableActions, setTableActions] = useState([])
    const [rowActions, setRowActions] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [loading, setLoading] = useState(true);
    const [noRows, setNoRows] = useState(false);

    // Alert Consts
    const [isAlert, setIsAlert] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertHeader, setAlertHeader] = useState("");
    const [alertBody, setAlertBody] = useState("");

    // NOTE(Xander): In order to ensure the table is reloaded after certain actions, this value can be toggled
    // The reload will happen every time the value is changed, not simply when it is true
    const [reloadTable, setReloadTable] = useState(false);

    // NOTE(Xander): In order to use older Connect modals with the new table, we need to replicate Rails-Ajax functionality
    // This method ensures that all links generated by the table will render their modal on the same page without redirecting
    const ajaxCall = () => {
        $(".MuiList-root a").attr("data-remote",true);
    }

    // NOTE(Michael): this is use to detech when the user is finished typing in the search bar
    const [searchValue, setSearchValue] = useState('')
    useEffect(() => {
        const timer = setTimeout(() => {
            fetchFromBackend(searchValue)
        }, 500)
        if (searchValue.length === 0 && rows.length > 0) {
            setNoRows(false);
        }
        return () => clearTimeout(timer)
    }, [searchValue])

    useEffect(() => {
        getApplicationBuilds(null);
    }, [reloadTable]);

    const getApplicationBuilds = async (event) => {
        setLoading(true);
        try {
            const options = {
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-type': 'application/json'
                })
            };
            const response = await window.fetch(props.backend_uri, options);
            if (!response.ok) throw Error(response.statusText);
            const data = await response.json();
            setRows(data.rows);
            setTableActions(generateTableActions(data.table_actions));
            setRowActions(
                [
                    {
                        icon: 'more_horiz',
                        onClick: getRowActions,
                        listChildren: []
                    }
                ]
            );
            if (data.rows.length === 0) {
                setNoRows(true);
            } else {
                setNoRows(false);
            }
            setLoading(false);
          } catch (error) {
            console.error(error);
            setRows([]);
            setRowActions([]);
            setLoading(false);
            setNoRows(true);
        }
    }

    const apiCall = element => {
        try {
            // NOTE(Xander): This query grabs the rails CSRF token for requests
            let csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
            fetch(element.path, {
                method: element.method,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': csrf
                }
            }).then((response) => {
                setReloadTable(!reloadTable);
                if (response.status == 200 || response.ok == true) {
                    setAlertHeader("Successfully Performed Action");
                    // setAlertBody("Aren't you a cool guy!");
                    setAlertSeverity("success");
                } else {
                    setAlertHeader(`${response.status}: ${response.statusText}`);
                    setAlertBody("Please Try Again Later");
                    setAlertSeverity("error");
                }
                setIsAlert(true);
            }).catch((error) => {
                console.error(error);
            });
        } catch (error) {
            console.error(error);
        }
    }

    function generateTableActions(actions) {
        const tableActions = [
            {
                icon: "add_circle_outline",
                listChildren: []
            },
            {
                icon: "sync",
                label: "Refresh Application Builds",
                onClick: getApplicationBuilds,
            }
        ]
        actions.forEach(element => {
            tableActions[0].listChildren.push(
                {
                    label: element.label,
                    href: element.path,
                    onClick: ajaxCall,
                    disabled: element.disabled
                }
            )
        });
        return tableActions
    }

    const getRowActions = async (event) => {
        try {
            let csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
            const options = {
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'X-CSRF-Token': csrf
                })
            };
            const response = await window.fetch('/admin/tools/application_builds/' + $(event.row.id)[0].innerHTML + '/actions', options);
            if (!response.ok) throw Error(response.statusText);
            const actions = await response.json();
            setRowActions(regenerateRowActions(actions));
        } catch (error) {
            console.error(error)
        }
    }

    function regenerateRowActions(actions) {
        return [
            {
                dsGetActionData: (row) => {
                    var listChildren = []
                    row.actions = actions
                    if (row.actions != null) {
                        var template_link = {}
                        row.actions.forEach((element) => {
                            template_link = {
                                label: element.label
                            };
                            if (element.ajaxCall) {
                                template_link.onClick = ajaxCall
                            }
                            if (element.method === "get") {
                                template_link.href = element.path
                            } else {
                                template_link.onClick = () => {
                                    apiCall(element);
                                }
                            }
                            if (element.disabled) {
                                template_link.disabled = true
                            }
                            listChildren.push(template_link);
                        });
                        return {
                            icon: 'more_horiz',
                            listChildren: listChildren,
                            onClick: getRowActions
                        };
                    }
                }
            }
        ];
    }

    const fetchFromBackend = async (args) => {
        if (args.length != 0){
            setFetching(true);
            if (props.backend_uri.includes("?")){
                var url = props.backend_uri +"&";
            }else{
                var url = props.backend_uri + "?";
            }
            
            try {
                const options = {
                    headers: new Headers({
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    })
                };
                const response = await window.fetch(url+new URLSearchParams({
                    sSearch: args, iDisplayLength: 200}), options);
                if (!response.ok) throw Error(response.statusText);
                const data = await response.json();
                var ids = new Set(rows.map(d => d.id));
                var merged = [...rows, ...data.rows.filter(d => !ids.has(d.id))];
                if (data.rows.length === 0) {
                    setNoRows(true);
                }
                setRows(merged);
                setFetching(false);
            } catch (error) {
                console.error(error)
                setFetching(false);
            }
        }
      }

    return (
        <AutoThemeProvider>
            <Alert
                body={alertBody}
                dismissible
                dsOnClose={() => {
                    setIsAlert(false);
                }}
                e2e="applicationAlert"
                header={alertHeader}
                open={isAlert}
                severity={alertSeverity}
            />
            <Table
                loading={loading}
                columns={[
                    {
                        field: 'id',
                        label: 'ID',
                        labelTooltip: 'A unique identifier',
                        dsRenderCell: ({value}) => {
                            return <div> {ReactHtmlParser (value)} </div>
                        }
                    },
                    {
                        field: 'name',
                        label: 'Name'
                    },
                    {
                        field: 'application_name',
                        label: 'Application Name'
                    },
                    {
                        field: 'region',
                        label: 'Region'
                    },
                    {
                        field: 'version',
                        label: 'Version'
                    },
                    {
                        field: 'description',
                        label: 'Description'
                    },
                    {
                        field: 'application_type',
                        label: 'Application Type'
                    },
                    {
                        field: 'deleted',
                        label: 'Deleted'
                    },
                    {
                        field: 'file_size',
                        label: 'Size'
                    }
                  ]}
                e2e={props.table_name}
                rows={rows}
                uniqueKey={props.table_name}
                searchable
                rowsPerPage={20}
                rowActions={rowActions}
                tableActions={tableActions}
                orderable={true}
                showNoRowsMessage={noRows}
                dsOnSearchChange={(args) =>  setSearchValue(args)}
            >
            </Table>
            <p style={fetching ? {} : { display: 'none' }}>  
                <Spinner variant='linear'/>
                <p>
                    Fetching from the database
                </p>
            </p>
        </AutoThemeProvider>
    );
};

ApplicationBuildsTable.propTypes = {
  rows: PropTypes.array
};

export default ApplicationBuildsTable;