import { AutoThemeProvider, Button, Dropdown, DropdownItem, Grid } from "@platform-ui/design-system"
import React from "react"
import $ from "jquery";

interface GridViewHeaderProps {
  title: string;
  headerActions?: any;
  headerActionText?: string;
}

export const GridViewHeader = ({
  title,
  headerActions,
  headerActionText,
}: GridViewHeaderProps) => {
  const [dropdownAnchor, setDropdownAnchor] = React.useState(null);
  const handleOpenDropdown = (event:any) => {
    setDropdownAnchor(event.currentTarget);
  }
  const handleCloseDropdown = () => {
    setDropdownAnchor(null);
  }

  return (
    <AutoThemeProvider>
      <Grid container sx={{marginBottom: '16px', marginRight: '16px'}}>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <h2>{title}</h2>
        </Grid>
        {headerActions.length != 0 && <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{textAlign: 'right'}}>
          <Button
            dsOnClick={handleOpenDropdown}
            e2e="myButton"
            sx={{marginTop: '16px'}}
          >
            {headerActionText}
          </Button>
          <Dropdown
            a11yId={'dropDown'}
            anchorEl={dropdownAnchor}
            dsOnClose={handleCloseDropdown}
            open={Boolean(dropdownAnchor)}
          >
            {headerActions.map(action => {
              return <DropdownItem dsOnClick={handleCloseDropdown}><a href={action.path} data-remote={true}>{action.label}</a></DropdownItem>
            })}
          </Dropdown>
        </Grid>}
      </Grid>
    </AutoThemeProvider>
  )
}

export default GridViewHeader