import { AutoThemeProvider, Tab, Tabs } from '@platform-ui/design-system';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import GenericTable from '../../Tables/components/GenericTable';
import { HISTORIES_TABLE_PROPS, INTEGRATION_APPS_SETTINGS_TABLE_PROPS } from '../../Tables/constants';
import * as ReactDOM from 'react-dom/client';

const AdminIntegrationAppsSettingsView = (props) => {

    const [tabs, setTabs] = useState(0);

    const root = ReactDOM.createRoot(document.getElementById("pageHeader"));
    root.render(
        <AutoThemeProvider>
        <h2>{"Apps Settings"}</h2>
        <Tabs
                    a11yLabel="tab"
                    value={tabs}
                    dsOnChange={(_, newIndex) => {
                        if (newIndex === 0) {
                            setTabs(0);
                        }
                        if (newIndex === 1) {
                            setTabs(1);
                        }
                        }}
            
                    e2e="apps-settings-tabs"
                    >
                    <Tab
                        id="apps-settings-tab"
                        e2e="apps-settings"
                        label="Apps Settings"
                        aria-controls={""}
                    />
                    <Tab
                        id="history-tab"
                        e2e="history"
                        label="History"
                        aria-controls={""}
                    />
                    
                </Tabs>
                </AutoThemeProvider>
      );

    return (
        <AutoThemeProvider>
            {tabs === 0 && <GenericTable {...INTEGRATION_APPS_SETTINGS_TABLE_PROPS} {...props.tables.integration_apps_settings_table}/>}
            {tabs === 1 && <GenericTable {...HISTORIES_TABLE_PROPS} {...props.tables.histories_table}/>}
        </AutoThemeProvider>
    )
}

AdminIntegrationAppsSettingsView.propTypes = {
    tables: PropTypes.object
}
export default AdminIntegrationAppsSettingsView;