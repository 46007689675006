import { Box } from "@mui/material";
import { AutoThemeProvider, Button, Card, Dropdown, DropdownItem, Grid, Chip } from "@platform-ui/design-system";
import React, { useState } from "react";
import Connect from "../Connect/Connect";

function SolutionInstanceTile(props: any) {
  const [isVisible, setIsVisible] = useState(true)
  const [dropdownAnchor, setDropdownAnchor] = useState(null);
  const [enabled, setEnabled] = useState(props.connector.node.enabled)
  let connector = props.connector
  let imagePath = Connect.setImagePath(connector.node.solution.title)
  let zuoraImageStyling = {'paddingTop':'20px','paddingBottom':'25px'}

  const handleOpenDropdown = (event:any) => {
    setDropdownAnchor(event.currentTarget);
  }

  const handleCloseDropdown = () => {
    setDropdownAnchor(null);
  }

  function toggleSolutionInstance(connector: any) {
    let csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    fetch(window.location.href + '/' + connector.node.solution.id + '/' + connector.node.id + '/update', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      },
      body: JSON.stringify({enabled: !connector.node.enabled})
    }).then(function(res){
      if (!res.ok) {
        throw new Error(res.statusText)
      }
      return res.json(); 
    }).then(response => {
      setEnabled(!enabled)
    }).catch((error) => {
      Connect.log(error);
    })
  }

  function deleteSolutionInstance(connector: any) {
    // NOTE(Xander): This query grabs the rails CSRF token for requests
    let csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    fetch(window.location.href + '/' + connector.node.solution.id + '/' + connector.node.id + '/destroy', {
      method: 'Delete',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      }
    }).then ((response) => {
      setIsVisible(false)
      console.log(response)
    }).catch((error) => {
      Connect.log(error);
    });
  }

  return (
    <AutoThemeProvider>
      {isVisible && <Grid item xl={2} lg={2} md={4} sm={5} xs={5}>
        <Card
          variant="outlined"
          id={connector.node.id} 
          header={<Box onClick={(event)=>{window.location.href = window.location.href + '/' + connector.node.solution.id + '/' + connector.node.id}} style={{cursor:'pointer'}}><img src={imagePath} height="75px" width="150px" style={(imagePath.includes('/Zuora-logo.png') ? zuoraImageStyling : {})}/><Chip
          label="default"
          state="default"
        /></Box>}
          body={<Box onClick={(event)=>{window.location.href = window.location.href + '/' + connector.node.solution.id + '/' + connector.node.id}} style={{cursor:'pointer'}}><div><div style={{height:'39.094px', overflow:'hidden', textOverflow:'ellipsis'}}><h3>{connector.node.name}</h3></div>{connector.node.solution.title}</div></Box>}
          headerAction={
            <div>
              <Button icon={'menu'} dsOnClick={handleOpenDropdown}/>
              <Dropdown
                a11yId={'dropDown'}
                anchorEl={dropdownAnchor}
                dsOnClose={handleCloseDropdown}
                open={Boolean(dropdownAnchor)}
              >
                <DropdownItem dsOnClick={() => {window.location.href = window.location.href + '/' + connector.node.solution.id + '/' + connector.node.id}}>
                  Show
                </DropdownItem>
                <DropdownItem dsOnClick={() => {toggleSolutionInstance(connector)}}>
                  {enabled ? 'Disable' : 'Enable'}
                </DropdownItem>
                <DropdownItem dsOnClick={() => {deleteSolutionInstance(connector)}}>
                  Delete
                </DropdownItem>
              </Dropdown>
            </div>
          }
        />
      </Grid>}
    </AutoThemeProvider>
  )
}

export default SolutionInstanceTile;