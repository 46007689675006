import { AutoThemeProvider, Table, Spinner } from '@platform-ui/design-system';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';

const HistoriesTable = (props) => {
    // Table Consts
    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(true);
    const [fetching, setFetching] = useState(false);
    const [noRows, setNoRows] = useState(false);

    // NOTE(Xander): In order to ensure the table is reloaded after certain actions, this value can be toggled
    // The reload will happen every time the value is changed, not simply when it is true
    const [reloadTable, setReloadTable] = useState(false);

    // NOTE(Michael): this is use to detech when the user is finished typing in the search bar
    const [searchValue, setSearchValue] = useState('')
    useEffect(() => {
        const timer = setTimeout(() => {
            fetchFromBackend(searchValue)
        }, 500)
        if (searchValue.length === 0 && rows.length > 0) {
            setNoRows(false);
        }
        return () => clearTimeout(timer)
    }, [searchValue])

    useEffect(() => {
        getHistory(null);
    }, [reloadTable]);

    const getHistory = async (event) => {
        setLoading(true);
        const URL = props.backend_uri;
        try {
            const options = {
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-type': 'application/json'
                })
            };
            const response = await window.fetch(props.backend_uri, options);
            if (!response.ok) throw Error(response.statusText);
            const data = await response.json();
            setRows(data.rows);
            if (data.rows.length === 0) {
                setNoRows(true);
            } else {
                setNoRows(false);
            }
            setLoading(false);
          } catch (error) {
            console.error(error);
            setRows([]);
            setRowActions([]);
            setLoading(false);
            setNoRows(true);
        }
    }

    const fetchFromBackend = async (args) => {
        if (args.length != 0){
            setFetching(true);
            if (props.backend_uri.includes("?")){
                var url = props.backend_uri +"&";
            }else{
                var url = props.backend_uri + "?";
            }
            
            try {
                const options = {
                    headers: new Headers({
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    })
                };
                const response = await window.fetch(url+new URLSearchParams({
                    sSearch: args, iDisplayLength: 100}), options);
                if (!response.ok) throw Error(response.statusText);
                const data = await response.json();
                var ids = new Set(rows.map(d => d.id));
                var merged = [...rows, ...data.rows.filter(d => !ids.has(d.id))];
                if (data.rows.length === 0) {
                    setNoRows(true);
                }
                setRows(merged);
                setFetching(false);
            } catch (error) {
                console.error(error)
                setFetching(false);
            }
        }
    }

    return (
        <AutoThemeProvider>
            <Table
                loading={loading}
                columns={[
                    {
                        field: 'versions__id',
                        label: 'ID',
                        labelTooltip: 'A unique identifier'
                    },
                    {
                        field: 'versions__event',
                        label: 'Event'
                    },
                    {
                        field: 'versions__created_at',
                        label: 'Created at'
                    },
                    {
                        field: 'versions__whodunnit',
                        label: 'Updated By',
                        dsRenderCell: ({ value }) => {
                            return <div> {ReactHtmlParser (value)} </div>
                        }
                    },
                    {
                        field: 'object_changes',
                        label: 'Changes'
                    }
                  ]}
                e2e={props.table_name}
                rows={rows}
                uniqueKey={props.table_name}
                searchable
                rowsPerPage={20}
                tableActions={[
                    {
                        icon: "sync",
                        label: "Refresh History",
                        onClick: getHistory,
                    }
                ]}
                orderable={true}
                showNoRowsMessage={noRows}
                dsOnSearchChange={(args) =>  setSearchValue(args)}
            >
            </Table>
            <p style={fetching ? {} : { display: 'none' }}>
                <Spinner variant="linear"/>
                <p>
                    Fetching from the database
                </p>
            </p>
        </AutoThemeProvider>
    );
};

HistoriesTable.propTypes = {
  rows: PropTypes.array,
  allowed_applications: PropTypes.array
};

export default HistoriesTable;