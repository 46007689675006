import * as React from 'react';
import {
  Icon as MuiIcon,
  IconProps as MUIIconProps,
  useTheme,
  Tooltip,
  SxProps,
  Theme,
} from '@mui/material';
import { ZuoraIcons, MaterialIconsOutlined, AllIconsType } from  '@platform-ui/design-system';

// NOTE(Xander): As of July 11th 2023, DS does not support calling the sx attribute on Icons
// This version of the Icon copied and modified from Design System 2.76.1
// TODO(Xander): If the sx attribute is added to the base DS component, remove this file and replace the modified icons

export interface IconProps
  extends Pick<MUIIconProps, 'color' | 'fontSize' | 'children' | 'className'> {
  /**
   * Adds margin to provide spacing as if rendered as Icon Button.
   * This is for internal use only.
   */
  __addMargin?: boolean;
  /**
   * Name of ligature to display.
   */
  body: AllIconsType;
  e2e?: string;
  /**
   * Some ligatures belong to both the 'filled' and 'outlined' set.
   * Override which to set to use via 'variant'.
   */
  variant?: 'filled' | 'outlined';
  tooltip?: React.ReactNode | string;
  hidden?: boolean;
  sx?: SxProps<Theme>;
}

const ZUORA_FONT_FAMILY = 'Zuora Icons';
const MATERIAL_FONT_FAMILY = 'Material Icons';
const MATERIAL_OUTLINED_FONT_FAMILY = 'Material Icons Outlined';

const getFontFamilyFromLigature = (ligature: string) => {
  if (MaterialIconsOutlined.indexOf(ligature) > -1) {
    return MATERIAL_OUTLINED_FONT_FAMILY;
  }
  return MATERIAL_FONT_FAMILY;
};

const getFontFamilyFromVariant = (variant: IconProps['variant']) => {
  if (variant === 'filled') {
    return MATERIAL_FONT_FAMILY;
  }
  return MATERIAL_OUTLINED_FONT_FAMILY;
};

const getFontFamily = (
  body: IconProps['body'],
  variant: IconProps['variant'],
) => {
  if (ZuoraIcons.indexOf(body) > -1) {
    return ZUORA_FONT_FAMILY;
  } else if (variant) {
    return getFontFamilyFromVariant(variant);
  } else {
    return getFontFamilyFromLigature(body);
  }
};

const Icon: React.FC<IconProps> = ({
  __addMargin,
  body,
  children,
  className,
  color = 'action',
  e2e,
  fontSize,
  variant,
  tooltip,
  hidden,
  sx,
}: IconProps) => {
  const theme = useTheme();
  return (
    <Tooltip
      title={tooltip || ''}
      PopperProps={{
        ...(e2e && {
          'data-e2e': `${e2e}-tooltip`,
        }),
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -8],
            },
          },
        ],
      }}
    >
      <MuiIcon
        color={color}
        fontSize={fontSize}
        className={className}
        {...(e2e && { 'data-e2e': e2e })}
        style={{
          ...(__addMargin && { margin: theme.spacing(1.5) }),
          ...(hidden && { visibility: 'hidden' }),
          fontFamily: `"${getFontFamily(body, variant)}"`,
        }}
        sx={sx}
      >
        {body}
        {children}
      </MuiIcon>
    </Tooltip>
  );
};

Icon.defaultProps = {
  color: 'action',
};

export default Icon;