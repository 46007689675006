export const AVAILABLE_CONNECTORS_QUERY_PARAMS = {
  queryParams: [
    'name',
    'tags'
  ]
}

export const MY_CONNECTORS_QUERY_PARAMS = {
  queryParams: [
    'name'
  ]
}

export const TENANT_LOGINS_FILTER_KEYS = ['tenant_type', 'environment', 'status', 'authentication_type']

export const TENANT_LOGINS_FILTERS = {
  tenant_type: [
    'Amazon',
    'Amplitude',
    'Apple',
    'Custom',
    'Datatrans',
    'Epaiement',
    'GoToBilling',
    'GooglePlay',
    'Insights',
    'Intacct',
    'Marketo',
    'Mixpanel',
    'Netsuite',
    'Salesforce',
    'Schibsted',
    'Sftp',
    'SiPay',
    'Whatcounts',
    'Zendesk',
    'Zuora'
  ],
  environment: [
    'Performance',
    'Production',
    'Sandbox',
    'Services',
    'Staging',
    'Test',
    'Unknown'
  ],
  status: [
    'Active',
    'Invalid Login',
    'Inactive',
    'Deactivated',
    'Invalid Session',
    'Unknown',
    'Restricted IP',
    'No Service',
    'Unsupported API Version',
    'Locked',
    'Timeout',
    'Expired'
  ],
  authentication_type: [
    'Basic',
    'OAUTH',
    'Session',
    'SSH'
  ]
}

export const INTEGRATION_APPS_FILTER_KEYS = ['product', 'category'] //, 'partner'

export const INTEGRATION_APPS_FILTERS = {
  product: [
    'Zuora Billing',
    'Zuora Revenue',
    'Zuora Payments',
    'Zephr'
  ],
  category: [
    'CRM',
    'AppStore',
    'CPQ',
    'ERP',
    'Data Warehouse',
    'Payment Gateway',
    'Query Service',
    'Cloud Object Storage',
    'Database',
    'Coming Soon'
  ]//,
  //partner: [
  //  'Amazon',
  //  "Oracle",
  //  "Salesforce",
  //  "Avalara"
  //],
}

export const INTEGRATION_APPS_FEATURED_QUERY_PARAMS = ['&featured=true']