import { Button, Grid, TextField, Dropdown, DropdownItem, Alert } from '@platform-ui/design-system';
import React, { useState } from 'react';

const NetsuiteLoginsForm = (props) => {
  const [dropdownAnchor, setDropdownAnchor] = React.useState(null);
  const [authType, setAuthType] = useState(props.login.authentication_type)
  const [isAllAlertOpen, setAllAlertOpen] = useState(true);
  const errorTypes = ['username', 'password', 'user_info']
  const handleOpenDropdown = (event: any) => {
    setDropdownAnchor(event.currentTarget);
  }
  const handleCloseDropdown = () => {
    setDropdownAnchor(null);
  }
  const handleAuthTypeChange = (newAuthType) => {
    setAuthType(newAuthType);
    props.handleChange({
      target: {
        name: 'authentication_type',
        value: newAuthType,
      },
    });
  };
  const handleAllAlertClose = (event) => {
    setAllAlertOpen(false)
  }

  return (
    <Grid container sx={{ padding: '10px 32px' }}>
      {props.orgLogin.error && isAllAlertOpen && (
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ padding: '16px' }}>
          {errorTypes.map((errorType) =>
            props.orgLogin.error[errorType] && props.orgLogin.error[errorType].map((errorMessage) => (
              <Alert
                key={errorMessage}
                body={errorMessage}
                dismissible
                dsOnClose={handleAllAlertClose}
                e2e="myAlert"
                header={errorType}
                open={isAllAlertOpen}
              />
            ))
          )}
        </Grid>
      )}

      <Grid item xl={6} lg={6} md={6} sm={6} xs={6} sx={{ padding: '16px' }}><div>

      </div></Grid>
      <Grid item xl={6} lg={6} md={6} sm={6} xs={6} sx={{ padding: '16px' }}>
        <Button
          dsOnClick={handleOpenDropdown}
          variant='outlined'
          endIcon={Boolean(dropdownAnchor) ? 'expand_less' : 'expand_more'}
        >
          {authType}
        </Button>
        <Dropdown
          a11yId='dropDown'
          anchorEl={dropdownAnchor}
          dsOnClose={handleCloseDropdown}
          open={Boolean(dropdownAnchor)}
        >
          <DropdownItem dsOnClick={() => { handleAuthTypeChange('OAUTH') }}>
            OAUTH
          </DropdownItem>
          <DropdownItem dsOnClick={() => { handleAuthTypeChange('Basic') }}>
            Basic
          </DropdownItem>

        </Dropdown>
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={6} xs={6} sx={{ padding: '12px' }}>
        <TextField
          fullWidth
          dsOnBlur={function noRefCheck() { }}
          dsOnChange={props.handleChange}
          dsOnFocus={function noRefCheck() { }}
          label="Username"
          placeholder="Username"
          name='username'
          value={props.login.username || ''}
        /></Grid>
      <Grid item xl={6} lg={6} md={6} sm={6} xs={6} sx={{ padding: '12px' }}>
        <TextField fullWidth
          dsOnBlur={function noRefCheck() { }}
          dsOnChange={props.handleChange}
          dsOnFocus={function noRefCheck() { }}
          label="Password"
          placeholder="Password"
          type='password'
          name='password'
          value={props.login.password || ''}
        /></Grid>
      <Grid item xl={6} lg={6} md={6} sm={6} xs={6} sx={{ padding: '12px' }}>
        <TextField fullWidth
          dsOnBlur={function noRefCheck() { }}
          dsOnChange={props.handleUserInfoChange}
          dsOnFocus={function noRefCheck() { }}
          label="Account ID"
          placeholder="Account ID"
          name='AccountID'
          value={props.login.user_info.AccountID || ''}
        /></Grid>
      <Grid item xl={6} lg={6} md={6} sm={6} xs={6} sx={{ padding: '12px' }}>
        <TextField fullWidth
          dsOnBlur={function noRefCheck() { }}
          dsOnChange={props.handleUserInfoChange}
          dsOnFocus={function noRefCheck() { }}
          label="Application ID"
          placeholder="Application ID"
          name='ApplicationID'
          value={props.login.user_info.ApplicationID || ''}
        /></Grid>
      {authType == 'OAUTH' && (
        <>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6} sx={{ padding: '12px' }}>
            <TextField fullWidth
              dsOnBlur={function noRefCheck() { }}
              dsOnChange={props.handleUserInfoChange}
              dsOnFocus={function noRefCheck() { }}
              label="Customer Key"
              placeholder="Customer Key"
              name='customerKey'
              value={props.login.user_info.customerKey || ''}
            /></Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6} sx={{ padding: '12px' }}>
            <TextField fullWidth
              dsOnBlur={function noRefCheck() { }}
              dsOnChange={props.handleUserInfoChange}
              dsOnFocus={function noRefCheck() { }}
              label="Customer Secret"
              placeholder="Customer Secret"
              name='customerSecret'
              type='password'
              value={props.login.user_info.customerSecret || ''}
            /></Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6} sx={{ padding: '12px' }}>
            <TextField fullWidth
              dsOnBlur={function noRefCheck() { }}
              dsOnChange={props.handleUserInfoChange}
              dsOnFocus={function noRefCheck() { }}
              label="Token ID"
              placeholder="Token ID"
              name='tokenID'
              value={props.login.user_info.tokenID || ''}
            /></Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6} sx={{ padding: '12px' }}>
            <TextField fullWidth
              dsOnBlur={function noRefCheck() { }}
              dsOnChange={props.handleUserInfoChange}
              dsOnFocus={function noRefCheck() { }}
              label="Token Secret"
              placeholder="Token Secret"
              name='tokenSecret'
              type='password'
              value={props.login.user_info.tokenSecret || ''}
            /></Grid>
        </>

      )
      }
    </Grid>
  )
}

export default NetsuiteLoginsForm