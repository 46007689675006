import { AutoThemeProvider } from '@platform-ui/design-system';
import React from 'react';
import GenericTable from '../Tables/components/GenericTable';
import { APPLICATIONS_TABLE_PROPS } from '../Tables/constants';

const ApplicationsView = (props) => {
    return (
        <AutoThemeProvider>
            <GenericTable {...APPLICATIONS_TABLE_PROPS} {...props} />     
        </AutoThemeProvider>
    );
};

export default ApplicationsView;