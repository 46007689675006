import { Button, Dropdown, DropdownItem, Grid } from "@platform-ui/design-system";
import React, { useState } from "react";

interface FilterFieldProps {
  filterKey: string;
  filterFields: string[];
  filterParams: Map<string, string>;
  setFilterParams: React.Dispatch<React.SetStateAction<Map<string, string>>>;
}

export const FilterField = ({
  filterKey,
  filterFields,
  filterParams,
  setFilterParams,
}: FilterFieldProps) => {
  const [dropdownAnchor, setDropdownAnchor] = React.useState(null);
  const handleOpenDropdown = (event:any) => {
    setDropdownAnchor(event.currentTarget);
  }
  const handleCloseDropdown = () => {
    setDropdownAnchor(null);
  }

  function modifyFilters(key: string, value: string) {
    let filters = new Map<string, string>();
    filterParams.forEach((v, k) => {
      filters.set(k, v);
    })
    filters.set(key, value);
    setFilterParams(filters);
  }

  function clearFilter(key: string) {
    let filters = new Map<string, string>();
    filterParams.forEach((v, k) => {
      if (key != k) {
        filters.set(k, v);
      }
    })
    setFilterParams(filters);
  }

  const styles = {
    filterNotSet: {
      margin: '0',
      background: '#E3EAEF',
      borderColor: '#E3EAEF',
      color: '#000000E5',
    },
    filterSet: {
      margin: '0',
      background: '#476176',
      borderColor: '#476176',
      color: 'white',
    },
  }

  function humanize(str) {
    var i, frags = str.split('_');
    for (i=0; i<frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  return (
    <Grid item>
        <Button 
          children={filterParams.has(filterKey) ? filterParams.get(filterKey) : humanize(filterKey)}
          dsOnClick={handleOpenDropdown}
          variant={'outlined'}
          endIcon={Boolean(dropdownAnchor) ? 'expand_less' : 'expand_more'}
          sx={filterParams.has(filterKey) ? styles.filterSet : styles.filterNotSet}
        />
        <Dropdown
          a11yId={'dropDown'}
          anchorEl={dropdownAnchor}
          dsOnClose={handleCloseDropdown}
          open={Boolean(dropdownAnchor)}
        >
          {filterParams.has && filterFields.map(filterValue => {
            if (filterParams.get(filterKey) != filterValue) {
              return (
                <DropdownItem dsOnClick={() => { modifyFilters(filterKey, filterValue); setDropdownAnchor(null) }}>
                  {filterValue}
                </DropdownItem>
              )
            }
          })}
          {filterParams.has(filterKey)  && <DropdownItem dsOnClick={() => { clearFilter(filterKey); setDropdownAnchor(null)}}>
            Clear
          </DropdownItem>}
        </Dropdown>
      </Grid>
  )
}

export default FilterField
