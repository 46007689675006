import { Alert, AutoThemeProvider, Table, TableProps, Spinner } from '@platform-ui/design-system';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import $ from 'jquery';

const OrganizationsTable = (props) => {
  // Table Consts
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [rowActions, setRowActions] = useState([]);
  const [tableActions, setTableActions] = useState([])
  const [noRows, setNoRows] = useState(false);

  // Alert Consts
  const [isAlert, setIsAlert] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertHeader, setAlertHeader] = useState("");
  const [alertBody, setAlertBody] = useState("");

  // NOTE(Xander): In order to ensure the table is reloaded after certain actions, this value can be toggled
  // The reload will happen every time the value is changed, not simply when it is true
  const [reloadTable, setReloadTable] = useState(false);
  
  // NOTE(Xander): In order to use older Connect modals with the new table, we need to replicate Rails-Ajax functionality
  // This method ensures that all links generated by the table will render their modal on the same page without redirecting
  const ajaxCall = () => {
    $(".MuiList-root a").attr("data-remote",true);
  }

  useEffect(() => {
    getOrgs(null);
    }, [reloadTable]);

  // NOTE(Michael): this is use to detech when the user is finished typing in the search bar
  const [searchValue, setSearchValue] = useState('')
  useEffect(() => {
      const timer = setTimeout(() => {
          fetchFromBackend(searchValue)
      }, 500)
      if (searchValue.length === 0 && rows.length > 0) {
        setNoRows(false);
      }
      return () => clearTimeout(timer)
  }, [searchValue])

  const getOrgs = async (event) => {
    setLoading(true);
    const URL = props.backend_uri;
    try {
        const options = {
            headers: new Headers({
                'Accept': 'application/json',
                'Content-type': 'application/json'
            })
        };
        const response = await window.fetch(props.backend_uri, options);
        if (!response.ok) throw Error(response.statusText);
        const data = await response.json();
        setRows(data.rows);
        setTableActions(generateTableActions(data.table_actions));
        setRowActions(
          [
              {
                  icon: 'more_horiz',
                  onClick: getRowActions,
                  listChildren: []
              }
          ]
        );
        if (data.rows.length === 0) {
          setNoRows(true);
        } else {
          setNoRows(false);
        }
        setLoading(false);
      } catch (error) {
        setRows([]);
        setRowActions([]);
        setLoading(false);
        setNoRows(true);
    }
  }
  const apiCall = element => {
    try {
        // NOTE(Xander): This query grabs the rails CSRF token for requests
        let csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
        fetch(element.path, {
            method: element.method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-Token': csrf
            }
        }).then((response) => {
            setReloadTable(!reloadTable);
            if (response.status == 200 || response.ok == true) {
                setAlertHeader("Successfully Performed Action");
                // setAlertBody("Aren't you a cool guy!");
                setAlertSeverity("success");
            } else {
                setAlertHeader(`${response.status}: ${response.statusText}`);
                setAlertBody("Please Try Again Later");
                setAlertSeverity("error");
            }
            setIsAlert(true);
        }).catch((error) => {
            console.error(error);
        });
    } catch (error) {
        console.error(error);
    }
  }
  
  const getRowActions = async (event) => {
    try {
        let csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
        const options = {
            headers: new Headers({
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'X-CSRF-Token': csrf
            })
        };
        const response = await window.fetch('/admin/organizations/' + event.row.id + '/actions', options);
        if (!response.ok) throw Error(response.statusText);
        const actions = await response.json();
        setRowActions(regenerateRowActions(actions));
    } catch (error) {
        console.error(error)
    }
}

function regenerateRowActions(actions) {
    return [
        {
            dsGetActionData: (row) => {
                var listChildren = []
                row.actions = actions
                if (row.actions != null) {
                    var template_link = {}
                    row.actions.forEach((element) => {
                        template_link = {
                            label: element.label
                        };
                        if (element.ajaxCall) {
                            template_link.onClick = ajaxCall
                        }
                        if (element.method === "get") {
                            template_link.href = element.path
                        } else {
                            template_link.onClick = () => {
                                apiCall(element);
                            }
                        }
                        if (element.disabled) {
                            template_link.disabled = true
                        }
                        listChildren.push(template_link);
                    });
                    return {
                        icon: 'more_horiz',
                        listChildren: listChildren,
                        onClick: getRowActions
                    };
                }
            }
        }
    ];
}

  function generateTableActions(allowed_applications) {
    var listChildren =  []
    allowed_applications.forEach(element => {
      listChildren.push(
            {
                label: element.label,
                href: element.path,
                onClick: ajaxCall
            }
        )
    });
    return [
      {
          icon: "add_circle_outline",
          listChildren: listChildren
      },
      {
          icon: "sync",
          label: "Refresh Organizations",
          onClick: getOrgs,
      }
    ]
  }

  const fetchFromBackend = async (args) => {
    if (args.length != 0){
        setFetching(true);
        if (props.backend_uri.includes("?")){
            var url = props.backend_uri +"&";
        }else{
            var url = props.backend_uri + "?";
        }
        
        try {
            const options = {
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-type': 'application/json'
                })
            };
            const response = await window.fetch(url+new URLSearchParams({
                sSearch: args, iDisplayLength: 100}), options);
            if (!response.ok) throw Error(response.statusText);
            const data = await response.json();
            var ids = new Set(rows.map(d => d.id));
            var merged = [...rows, ...data.rows.filter(d => !ids.has(d.id))];
            if (data.rows.length === 0) {
              setNoRows(true);
            }
            setRows(merged);
            setFetching(false);
        } catch (error) {
            console.error(error)
            setFetching(false);
        }
    }
  }

  return (
    <AutoThemeProvider>
      <Alert
        body={alertBody}
        dismissible
        dsOnClose={() => {
            setIsAlert(false);
        }}
        e2e="organizationAlert"
        header={alertHeader}
        open={isAlert}
        severity={alertSeverity}
      />
      <Table
        columns={[
          {
            field: 'name',
            label: 'Name'
          },
          {
            field: 'id',
            label: 'ID'
          },
          {
            field: 'type',
            label: 'Type'
          },
          {
            field: 'salesforce_id',
            label: 'Salesforce ID'
          },
          {
            field: 'account_id',
            label: 'Account ID'
          },
          {
            field: 'trusted_emails',
            label: 'Trusted Emails'
          },
          {
            field: 'tools_access',
            label: 'Tools Access'
          },
          {
            field: 'tools_expire',
            label: 'Tools Expire'
          },
          {
            field: 'user_count',
            label: 'Users'
          }
        ]}
        loading={loading}
        e2e="organizationsTable"
        rows={rows}
        uniqueKey="organizations-table"
        searchable
        rowsPerPage={20}
        rowActions={rowActions}
        tableActions={tableActions}
        showNoRowsMessage={noRows}
        dsOnSearchChange={(args) =>  setSearchValue(args)}
      />
      <p style={fetching ? {} : { display: 'none' }}>  
        <Spinner variant="linear"/>
        <p>
            Fetching from the database
        </p>
      </p>
    </AutoThemeProvider>
  )
}

OrganizationsTable.propTypes = {
  organization: PropTypes.object
}

export default OrganizationsTable;