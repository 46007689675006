import * as React from 'react';
import { TextField, InputAdornment, useTheme } from '@mui/material';
import { Icon, Grid, Dropdown, DropdownItem, Button } from '@platform-ui/design-system';
import FilterField from './FilterField';

const placeholderStyles = {
  fontStyle: 'italic',
  fontSize: '14px',
};

interface SearchBarProps {
  e2e?: string;
  search: string;
  searchPlaceholder?: string;
  onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  filters?: object;
  filterKeys?: string[];
  filterParams?: Map<string, string>;
  setFilterParams?: React.Dispatch<React.SetStateAction<Map<string, string>>>;
}

export const SearchBar = ({
  e2e,
  search,
  searchPlaceholder = 'Type here to filter results...',
  onSearch,
  filters,
  filterKeys,
  filterParams,
  setFilterParams,
}: SearchBarProps) => {
  const [focused, setFocused] = React.useState(false);
  const theme = useTheme();

  function modifyFilters(key: string, value: string) {
    let filters = new Map<string, string>();
    filterParams.forEach((v, k) => {
      filters.set(k, v);
    })
    filters.set(key, value);
    setFilterParams(filters);
  }

  function clearFilter(key: string) {
    let filters = new Map<string, string>();
    filterParams.forEach((v, k) => {
      if (key != k) {
        filters.set(k, v);
      }
    })
    setFilterParams(filters);
  }

  const styles = {
    standardSearchField: {
      fontSize: '14px',
    },
    filterSearchField: {
      fontSize: '14px',
      border: '1px solid rgba(0, 0, 0, 0.24)',
      padding: 0,
      borderRadius: '4px',
      height: '32px',
    },
  }

  return (
    <Grid container>
      <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        <TextField
          {...(e2e && { 'data-e2e': `${e2e}-table-search` })}
          className="search"
          sx={{
            padding: `${theme.spacing(1.5)} 0`,
            display: 'flex',
            alignItems: 'center',
          }}
          type="text"
          variant="standard"
          value={search}
          placeholder={searchPlaceholder}
          onChange={onSearch}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          fullWidth
          style={filters ? styles.filterSearchField : styles.standardSearchField}
          InputProps={{
            sx: {
              '& input': {
                '&::placeholder': placeholderStyles,
                '&::-webkit-input-placeholder': placeholderStyles,
                '&:-moz-placeholder': placeholderStyles,
                '&::-moz-placeholder': placeholderStyles,
                '&:-ms-input-placeholder': placeholderStyles,
              },
            },
            startAdornment: (
              <InputAdornment position="start">
                <Icon
                  color={focused ? 'primary' : 'action'}
                  body="search"
                  __addMargin
                />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid container item xs={9} sm={9} md={9} lg={9} xl={9}>
        {filterKeys && filterKeys.map(filterKey => {
          return (
            <FilterField filterKey={filterKey} filterFields={filters[filterKey]} filterParams={filterParams} setFilterParams={setFilterParams}/>
          )
        })}
      </Grid>
    </Grid>
  );
};

export default SearchBar