import ReactOnRails from 'react-on-rails';

import AdminIntegrationAppsSettingsView from './bundles/Admin/IntegrationAppsSettings/AdminIntegrationAppsSettingsView';
import AdminIntegrationAppsView from './bundles/Admin/IntegrationApps/AdminIntegrationAppsView';
import AmplitudeProviderWrapper from './bundles/Amplitude/AmplitudeProviderWrapper';
import AppDownloadsTable from './bundles/Tables/components/AppDownloadsTable';
import AppDownloadsView from './bundles/AppDownloads/AppDownloadsView';
import ApplicationBuildsTable from './bundles/Tables/components/ApplicationBuildsTable';
import ApplicationBuildsView from './bundles/ApplicationBuilds/ApplicationBuildsView';
import ApplicationsTable from './bundles/Tables/components/ApplicationsTable';
import ApplicationsView from './bundles/Applications/ApplicationsView';
import AppsView from './bundles/Appstore/AppsView';
import ConfigWizard from './bundles/Connectors/ConfigWizard';
import ConnectorDetails from './bundles/Connectors/ConnectorDetails';
import ConnectorsView from './bundles/Connectors/ConnectorsView';
import Editor from './bundles/Admin/IntegrationApps/Editor/Editor';
import FilterField from './bundles/GridView/FilterField';
import GridTile from './bundles/GridView/GridTile';
import GridView from './bundles/GridView/GridView';
import GridViewHeader from './bundles/GridView/GridViewHeader'
import HistoriesTable from './bundles/Tables/components/HistoriesTable';
import IdentitiesTable from './bundles/Tables/components/IdentitiesTable';
import IdentitiesView from './bundles/Identities/IdentitiesView';
import IntegrationAppDetailsOverview from './bundles/IntegrationApps/IntegrationAppDetailsOverview';
import IntegrationAppDetailsView from './bundles/IntegrationApps/IntegrationAppDetailsView';
import IntegrationAppFormView from './bundles/Admin/IntegrationApps/IntegrationAppFormView';
import IntegrationAppsView from './bundles/IntegrationApps/IntegrationAppsView'
import Marketplace from './bundles/Connectors/Marketplace';
import NetsuiteLoginsForm from './bundles/TenantLogins/NetsuiteLoginsForm'
import NetsuiteLoginsView from './bundles/TenantLogins/NetsuiteLoginsView';
import SearchBar from './bundles/GridView/SearchBar';
import SFDCView from './bundles/Connectors/SFDCView';
import SolutionsInstanceTile from './bundles/Connectors/SolutionInstanceTile';
import TasksTable from './bundles/Tasks/TasksTable';
import TasksPartialView from './bundles/Tasks/TasksPartialView';
import TasksView from './bundles/Tasks/TasksView';
import TenantLoginsView from './bundles/TenantLogins/TenantLoginsView'
import TenantsView from './bundles/Tenants/TenantsView';
import TenantView from './bundles/Tenants/TenantView';
import TenantsTable from './bundles/Tables/components/TenantsTable';
import OrganizationsTable from './bundles/Tables/components/OrganizationsTable';
import OrganizationsView from './bundles/Organizations/OrganizationsView';
import OrganizationView from './bundles/Organizations/OrganizationView';
import UsersView from './bundles/Users/UsersView';
import WorkflowButton  from './bundles/Connectors/WorkflowButton'

ReactOnRails.setOptions({
  // traceTurbolinks: process.env.TRACE_TURBOLINKS, // eslint-disable-line no-undef
  // process.env.TRACE_TURBOLINKS -> error: development is not defined
  traceTurbolinks: true,
});

ReactOnRails.register({
  AdminIntegrationAppsSettingsView,
  AdminIntegrationAppsView,
  AmplitudeProviderWrapper,
  AppDownloadsTable,
  AppDownloadsView,
  ApplicationBuildsTable,
  ApplicationBuildsView,
  ApplicationsTable,
  ApplicationsView,
  AppsView,
  ConnectorDetails,
  ConnectorsView,
  Editor,
  GridTile,
  GridView,
  GridViewHeader,
  HistoriesTable,
  IdentitiesTable,
  IdentitiesView,
  IntegrationAppDetailsOverview,
  IntegrationAppDetailsView,
  IntegrationAppFormView,
  IntegrationAppsView,
  Marketplace,
  NetsuiteLoginsForm,
  NetsuiteLoginsView,
  SearchBar,
  SolutionsInstanceTile,
  TasksTable,
  TasksPartialView,
  TasksView,
  TenantLoginsView,
  TenantView,
  TenantsView,
  TenantsTable,
  OrganizationsTable,
  OrganizationsView,
  OrganizationView,
  UsersView,
  WorkflowButton,
  SFDCView
});