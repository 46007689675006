import { AutoThemeProvider } from '@platform-ui/design-system';
import PropTypes from 'prop-types';
import React from 'react';
import GenericTable from '../Tables/components/GenericTable';
import { TASKS_TABLE_PROPS } from '../Tables/constants';

const TasksView = (props) => {

    return (
        <AutoThemeProvider>
            <GenericTable {...TASKS_TABLE_PROPS} {...props.tables.task_table} />
        </AutoThemeProvider>
    )
}

TasksView.propTypes = {
    tables: PropTypes.object
}

export default TasksView;