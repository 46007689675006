import { AutoThemeProvider, Button, ButtonGroup, Tab,  Tabs, ToastController, Card, Chip, Grid, Icon, TextField, Toggle, Typography, PageHeader} from "@platform-ui/design-system";
import { AlertColor,Box, Divider } from "@mui/material"; 
import React, { useState } from "react";
import * as ReactDOM from 'react-dom/client';
import Flipper from "../Connect/Flipper";
import Connect from "../Connect/Connect";
import SearchBar from "../GridView/SearchBar";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function SFDCView(props: any) {
    const [tabs, setTabs] = useState(0);
    const root = ReactDOM.createRoot(document.getElementById("pageHeader"));
    const [searchValue, setSearchValue] = useState("");
    const [saved, setSavedValue] = useState(false)

    let zuoraImageStyling = {'paddingTop':'20px','paddingBottom':'25px'}
    let description = "The Zuora Connector for Salesforce CPQ provides a common integration mapping that allows your CPQ frontend to automate your subscription billing backend. The Connector integrates Salesforce CPQ Contracts and Salesforce CPQ Quotes with Zuora Subscriptions. The Connector also allows you to sync the Salesforce Product Catalog from Salesforce to Zuora. Product catalogs and the quoting/contracting process are aligned between Zuora and Salesforce CPQ as follows: Product Catalog: With Zuora Connector for Salesforce CPQ, you manage your product catalog in your Salesforce org and sync the product data over to Zuora. In Zuora, the products and prices are translated to Zuora products, rate plans, charges, and charge tiers. The information is tied to the subscriptions created from Salesforce CPQ and managed in Zuora for billing, payments, and other services provided in Zuora. Quoting and Contracting: When a quote is created in Salesforce CPQ, it will create or change a Salesforce CPQ Contract. In turn, these Salesforce CPQ Contracts are translated into the New, Amend, or Cancel Subscriptions in Zuora.  Zuora metrics, such as MRR and TCV, are also stored at the contract level and you can preview the metrics on the Salesforce CPQ contract.";

    
    function createData(
      name: string,
      calories: number,
      fat: number,
      carbs: number,
      protein: number,
    ) {
      return { name, calories, fat, carbs, protein };
    }
    
    const rows = [
      createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
      createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
      createData('Eclair', 262, 16.0, 24, 6.0),
      createData('Cupcake', 305, 3.7, 67, 4.3),
      createData('Gingerbread', 356, 16.0, 49, 3.9),
    ];

    root.render(
        <AutoThemeProvider>
            <h2>{"Salesforce Connector for CRM"}</h2>
            <Tabs
                a11yLabel="tab"
                value={tabs}
                dsOnChange={
                    (_, newIndex) => {
                        if (newIndex === 0) {
                            setTabs(0);
                        }
                        if (newIndex === 1) {
                            setTabs(1);
                        }
                        if (newIndex === 2) {
                            setTabs(2);
                        }
                    }
                }
                e2e="connectors-tabs"
                >
                    <Tab
                        id="markeplace-tab"
                        e2e="settings"
                        label="Settings" 
                        aria-controls={""}
                    />
                    <Tab
                        id="markeplace-tab"
                        e2e="field-mapping"
                        label="Field Mapping" 
                        aria-controls={""}
                    />
                    <Tab
                        id="my-connectors-tab"
                        e2e="execution"
                        label="Executions"
                        aria-controls={""}
                    />
            </Tabs>
        </AutoThemeProvider>
      );

    return (
        <AutoThemeProvider>
            {saved && <ToastController
              e2e="myToastController"
              message="Saved."
              severity="success"
            />}
            { tabs === 0  && <div><Card 
              variant="outlined"
              id={"1st-tab"}
              body={<Grid container>
                <Grid item xs={6}>
                    <div style={{float:'left', height:'inherit'}}>
                      <img src={Connect.setImagePath("Salesforce")} height="50%" width="100%"  style={(Connect.setImagePath("Salesforce").includes('/Zuora-logo.png') ? zuoraImageStyling : {})}/>
                    </div>
                  </Grid>
                  <Divider orientation="vertical" flexItem style={{marginTop:'16px', marginRight:'32px', marginLeft:'32px'}}/>
                  <Grid item xs={5}>
                    <PageHeader
                      title={<Typography e2e="page-title" variant="h5">
                        Salesforce Connector for CRM
                      </Typography>}
                      chip={<Chip label={"New"} state={'default'} />}
                    />
                    <Typography variant={"body1"} body={description} />
                      <Divider variant="middle" style={{marginTop:'20px', marginBottom:'20px', marginLeft:'0'}} />
                      <Grid container>
                        {<Grid item><Toggle label={"Enable"} dsOnChange={() => {}} checked={true}/></Grid>}
                      </Grid>
                  </Grid>
                <Box width="100%"/>
                  
            </Grid>
              }
            />
             <Box style={{ padding: 5 }} width="100%"/><Card 
             variant="outlined"
            id={"2nd-tab"}
            header={<Typography e2e="page-title" variant="h5">
              Credentials
            </Typography>}
            body={
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '45%' },
              }}
              noValidate
              autoComplete="off"
            >
                
              <div>
                <TextField label="Account Id" dsOnChange={function noRefCheck(){}} value={"8ac5454b-c98f-4dc4"} placeholder="Account Id" />
                <TextField label="Salesforce Username" dsOnChange={function noRefCheck(){}} value={"causeshort"} placeholder="Salesforce Username"/>
              </div>
              <div>
                <TextField label="Token Secret" dsOnChange={function noRefCheck(){}} value={"******************"} placeholder="Token Secret"/>
                <TextField label="Client ID" dsOnChange={function noRefCheck(){}} value={"9f76-620382faad9"} placeholder="Client ID"/>
              </div>
              <div>
                <TextField label="Client Secret" dsOnChange={function noRefCheck(){}} value={"******************"} placeholder="Client Secret" />
                <TextField label="Salesforce URL" dsOnChange={function noRefCheck(){}} value={"https://login.salesforce.com/"} placeholder="Salesforce URL" />
              </div>
              <Button
                dsOnClick={(event)=>{setSavedValue(true)
                  window.setTimeout(function(){

                    // Move to a new location or you can do something else
                    history.back()
            
                  }, 2500);
                  }}
                e2e="myButton">
                Save
              </Button>
            </Box>
            }
          /></div>}
            { tabs === 1  && <Card 
              id={"2nd-tab"}
              header={<Typography e2e="page-title" variant="h5">
              Map Source Field With Target field
            </Typography>}
              body={<Box
                component="form"
                sx={{
                  '& .MuiTextField-root': { m: 1, width: '100%' },
                  '& .MuiTypography-overline': { m: 1, width: '100%' }
                }}
                noValidate
                autoComplete="off"
              >
                <SearchBar 
                search={searchValue}
                onSearch={(event) => {setSearchValue(event.target.value)}}
              />
               <BasicTable />
              </Box>
              }
            />}
            { tabs === 2  && <Card 
              id={"3rd-tab"}
              body={<BasicTable />}
              header={<Typography e2e="page-title" variant="h5">
                History
              </Typography>}
            />}
        </AutoThemeProvider>
    );
}

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number,
) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Initial sync', 159, 6.0, 0, 0)
];

export function BasicTable() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Sync</TableCell>
            <TableCell align="right">Records</TableCell>
            <TableCell align="right">Duration</TableCell>
            <TableCell align="right">Errors</TableCell>
            <TableCell align="right">Retry</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.calories}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default SFDCView;