import { AutoThemeProvider } from '@platform-ui/design-system';
import React from 'react';
import GenericTable from '../Tables/components/GenericTable';
import { IDENTITIES_TABLE_PROPS } from '../Tables/constants';

const IdentitiesView = (props) => {
    return (
        <AutoThemeProvider>
            <GenericTable {...IDENTITIES_TABLE_PROPS} {...props} />
        </AutoThemeProvider>
    );
};

export default IdentitiesView;