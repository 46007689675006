import React from 'react';
import { AmplitudeProvider } from '../Amplitude/hooks/useAmplitude';
import { IdentityProvider } from '../IdentityProvider';
import IntegrationAppsView from '../IntegrationApps/IntegrationAppsView';
import IntegrationAppDetailsView from '../IntegrationApps/IntegrationAppDetailsView';

const AmplitudeProviderWrapper = (props) => {
  const renderComponent = (name: string) => {
    switch (name) {
      case 'IntegrationAppsView':
        return <IntegrationAppsView {...props} />;
      case 'IntegrationAppDetailsView':
        return <IntegrationAppDetailsView {...props} />;
      default:
        return <div><p>Unrecognized Component</p></div>;
    }
  };

  return (
    <IdentityProvider>
      <AmplitudeProvider>
        {renderComponent(props.name)}
      </AmplitudeProvider>
    </IdentityProvider>
  );
};

export default AmplitudeProviderWrapper;
