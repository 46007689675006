import { AutoThemeProvider } from '@platform-ui/design-system';
import React, { useState } from 'react';
import NetsuiteLoginsForm from './NetsuiteLoginsForm';

const $ = (window as any).$;

const NetsuiteLoginsView = (props) => {
  const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
  const [login, setLogin] = useState({
    authentication_type: props.login.authentication_type,
    tenant_type: "Netsuite",
    user_id: props.login.user_id,
    username: props.login.username,
    password: props.login.password,
    user_info: {
      AccountID: '',
      ApplicationID: '',
      customerKey: '',
      customerSecret: '',
      tokenID: '',
      tokenSecret: '',
    },
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setLogin((prevLogin) => ({
      ...prevLogin,
      [name]: value,
    }));
  };
  const handleUserInfoChange = (event) => {
    const { name, value } = event.target;
    setLogin((prevLogin) => ({
      ...prevLogin,
      user_info: {
        ...prevLogin.user_info,
        [name]: value
      },
    }));
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    fetch(props.url, {
      method: props.method,
      body: JSON.stringify({
        netsuite: login
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-CSRF-Token': csrf
      },
    })
      .then(() => {
        $('#z_hub_modal').modal("hide");
        $("#content .datatable").dataTable().api().draw(false);
        if (window.frameElement != null) {
          const iframe = window.parent.document.getElementById(window.frameElement.id);
          $(iframe).parents('.row').first().find("select[name$='_login_id]']").append('<option value="<%= @login.id %>"><%= @login.username %></option>').val('<%= @login.id %>');
          $(iframe).remove();
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const renderModal = () => {
    if (props.layout !== 'false') {
      return (
        <AutoThemeProvider>
          <form onSubmit={handleSubmit}>
            <div className="modal-dialog modal-lg">
              <div className="modal-content z-background">
                <i id="loading_form_icon" className="fa fa-spinner fa-spin"></i>
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    ×
                  </button>
                  <h4 className="modal-title">{props.name}</h4>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <i id="loading_form_icon" className="fa fa-spinner fa-spin"></i>
                    <NetsuiteLoginsForm {...props} orgLogin={props.login} login={login} authType={props.login.authentication_type} handleChange={handleChange} handleUserInfoChange={handleUserInfoChange} handleSubmit={handleSubmit} type={props.login.tenant_type} />
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default" data-dismiss="modal">
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Save Login
                  </button>
                </div>
              </div>
            </div>
          </form>
        </AutoThemeProvider>
      );
    } else {
      return (
        <AutoThemeProvider>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <i id="loading_form_icon" className="fa fa-spinner fa-spin" style={{ top: '30%' }}></i>
              <NetsuiteLoginsForm {...props} orgLogin={props.login} login={login} authType={props.login.authentication_type} handleChange={handleChange} handleUserInfoChange={handleUserInfoChange} handleSubmit={handleSubmit} type={props.login.tenant_type} />
            </div>
            <div className="row">
              <div className="col-md-12">
                <button type="submit" className="btn btn-primary pull-right">
                  Save Login
                </button>
                <button type="button" className="btn btn-default pull-right" data-dismiss="modal" style={{ marginRight: '10px' }}>
                  Close
                </button>
              </div>
            </div>
            <style>{`
                #content {
                  min-height: 0px !important;
                }
              `}</style>
          </form>
        </AutoThemeProvider>
      );
    }
  };

  return <>{renderModal()}</>;
}


export default NetsuiteLoginsView;