import { AlertColor, Divider } from "@mui/material";
import { Alert, AutoThemeProvider, Button, Card, Grid, Spinner, Typography } from "@platform-ui/design-system";
import React, { useEffect, useState } from "react";
import SearchBar from "./SearchBar";
import GridTile from "./GridTile";
import GridViewHeader from "./GridViewHeader";
import Connect from '../Connect/Connect';

interface GridViewProps {
  title?: string;
  headerActionText?: string;
  searchEnabled: boolean;
  backendUri: string;
  gridTitle?: string;
  connectorParams?: string[];
  connectionsView?: boolean;
  filters?: object;
  filterKeys?: string[];
  display?: number;
  queryParams?: string[];
  integrationView?: boolean;
  useElevationTiles?: boolean;
  tenantType?: string;
  keyboardNavDisabled?: boolean;
}

export const GridView = ({
  title,
  headerActionText,
  searchEnabled,
  backendUri,
  gridTitle,
  connectorParams,
  connectionsView = false,
  filters,
  filterKeys,
  display,
  queryParams,
  integrationView = false,
  useElevationTiles = false,
  tenantType = '',
  keyboardNavDisabled = false,
}: GridViewProps) => {
  // Table Consts
  const [tiles, setTiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noTiles, setNoTiles] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const displayedTiles = display || 12;

  // Alert Consts
  const [isAlert, setIsAlert] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertHeader, setAlertHeader] = useState("");
  const [alertBody, setAlertBody] = useState("");

  // Filter Consts
  const [filterParams, setFilterParams] = gridTitle == 'Zuora Connectors' && tenantType == 'RevPro' ? useState(new Map().set('product', 'Zuora Revenue')) : useState(new Map());

  // Header Consts
  const [headerActions, setHeaderActions] = useState([]);

  // NOTE(Xander): In order to ensure the table is reloaded after certain actions, this value can be toggled
  // The reload will happen every time the value is changed, not simply when it is true
  const [reloadTable, setReloadTable] = useState(false);

  useEffect(() => {
    getObjects(null);
  }, [reloadTable, currentPage, filterParams]);

  const getObjects = async (event) => {
    setLoading(true);
    // NOTE(Xander): Base url with page, configurable display tiles, and search
    let url = backendUri + "?page=" + currentPage + "&iDisplayLength=" + displayedTiles + (searchValue ? "&search=" + searchValue : "")

    // NOTE(Xander): Hardcoded additional query params for specific views to filter directly
    // query params should be in the format: '&<key>=<value>'
    if (queryParams) {
      queryParams.forEach(param => {
        url += param
      })
    }

    // NOTE(Xander): Searches window url for a given param, if it finds it, appends it to the call to the backend
    if (connectorParams) {
      connectorParams['queryParams'].forEach(param => {
        let tempQuery = new URLSearchParams(window.location.search)
        if (tempQuery.get(param)) {
          url = url + "&" + param + "=" + tempQuery.get(param)
        }
      })
    }

    if (filterParams.size > 0) {
      filterParams.forEach((value, field) => {
        url += "&" + field + "=" + value;
      });
    }

    try {
      const options = {
        headers: new Headers({
          'Accept': 'application/json',
          'Content-type': 'application/json'
        })
      };
      const response = await window.fetch(url, options);
      if (!response.ok) throw Error(response.statusText);
      const data = await response.json();
      setTiles(data.items);
      setTotalPages(data.totalPages);
      if (data.items.length === 0) {
        setNoTiles(true);
      } else {
        setNoTiles(false);
      }
      if (data.headerActions) {
        setHeaderActions(data.headerActions);
      }
      setLoading(false);
    } catch (error) {
      Connect.log(error);
      setTiles([]);
      setLoading(false);
      setNoTiles(true);
    }
  }

  const previousPage = () => {
    setCurrentPage(currentPage - 1)
  }

  const nextPage = () => {
    setCurrentPage(currentPage + 1)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentPage(1)
      getObjects(null)
    }, 500)
    if (searchValue.length === 0 && tiles.length > 0) {
      setNoTiles(false);
    }
    return () => clearTimeout(timer)
  }, [searchValue])

  const styles = {
    spinnerContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: '-webkit-fill-available'
    }
  }

  const apiCall = element => {
    try {
      // NOTE(Xander): This query grabs the rails CSRF token for requests
      let csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      fetch(element.path, {
        method: element.method,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrf
        }
      }).then((response) => {
        setReloadTable(!reloadTable);
        if (response.status == 200 || response.ok == true) {
          setAlertHeader("Successfully Performed Action");
          // setAlertBody("Aren't you a cool guy!");
          setAlertSeverity("success");
        } else {
          setAlertHeader(`${response.status}: ${response.statusText}`);
          setAlertBody("Please Try Again Later");
          setAlertSeverity("error");
        }
        setIsAlert(true);
      }).catch((error) => {
        Connect.log(error);
      });
    } catch (error) {
      Connect.log(error);
    }
  }

  return (
    <AutoThemeProvider>
      {title && <GridViewHeader title={title} headerActionText={headerActionText} headerActions={headerActions}/>}
      <Card id={"grid-view"}
        titleBar={true}
        header={gridTitle}
        body={
          <Grid container>
            {isAlert && <Grid item xs={12}>
              <Alert
                body={alertBody}
                dismissible
                dsOnClose={() => {
                    setIsAlert(false);
                }}
                e2e="taskAlert"
                header={alertHeader}
                open={isAlert}
                severity={alertSeverity as AlertColor}
              />
            </Grid>}
            {searchEnabled && <Grid item xs={12}>
              <SearchBar
                search={searchValue}
                onSearch={(event) => {setSearchValue(event.target.value)}}
                filters={filters}
                filterKeys={filterKeys}
                filterParams={filterParams}
                setFilterParams={setFilterParams}
              />
            </Grid>}
            {searchEnabled && <Grid item xs={12}>
              <Divider variant="middle" />
            </Grid>}
            {loading && <Grid item xs={12} sx={styles.spinnerContainer}>
              <Spinner />
            </Grid>}
            {!loading && tiles.map((tile, i) => {
              return (
                <GridTile {...tile} apiCall={apiCall} connectionsView={connectionsView} integrationView={integrationView} useElevationTiles={useElevationTiles}/>
              )
            })}
            {!loading && noTiles &&  <Grid item xs={12} >
              <Typography body='No Records Found' variant='body2' sx={styles.spinnerContainer}/>
            </Grid>}
          </Grid>
        }
        footer={ !keyboardNavDisabled && <Grid container justify="flex-end">
            {!loading && !noTiles && <Grid item>
              <Button dsOnClick={previousPage} icon="keyboard_arrow_left" size="small" disabled={currentPage == 1}></Button>
            </Grid>}
            {!loading && !noTiles && <Grid item>
              <Button dsOnClick={nextPage} icon="keyboard_arrow_right" size="small" disabled={currentPage == totalPages}></Button>
            </Grid>}
          </Grid>
        }
      />
    </AutoThemeProvider>
  )
}

export default GridView;