import { AutoThemeProvider } from '@platform-ui/design-system';
import React, { useState } from 'react';
import GridView from '../GridView/GridView';

function AppsView(props: any) {
  const [tabs, setTabs] = useState(0);

  return (
    <AutoThemeProvider>
      {tabs === 0 && <GridView {...props}/>}
    </AutoThemeProvider>
  )
}

export default AppsView;