import { Accordion, AutoThemeProvider, Card, Grid, Chip } from "@platform-ui/design-system";
import { Box } from '@mui/material';
import React from "react";
import Connect from "../Connect/Connect";
import { useFlipperHook } from '../Tables/helpers';

function Marketplace(props: any) {
  let zuoraImageStyling = {'paddingTop':'20px','paddingBottom':'25px'}
  const [demo_tenant_enabled, loading] = useFlipperHook('demo_tenant');
  const demo_billing = [{}]


  return (
    <AutoThemeProvider>
      {/* <Accordion header="Relevant Connectors" defaultExpanded body={
        <Grid container>
          {props.connectors.map((connector, i) => {
            connector.node.description = "Version 1.0.0"
            let imagePath = Connect.setImagePath(connector.node.title)
            return (
              <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
                <Box onClick={(event)=>{window.location.href = window.location.href + '/' + connector.node.id}} style={{cursor:'pointer'}}>
                  <Card
                    id={connector.node.id} 
                    header={<img src={imagePath} height="75px" width="150px" style={(imagePath.includes('/Zuora-logo.png') ? zuoraImageStyling : {})}/>}
                    body={<div><div style={{height:'39.094px', overflow:'hidden', textOverflow:'ellipsis'}}><h3>{connector.node.title}</h3></div>{connector.node.description}</div>}
                  />
                </Box>
              </Grid>
            )
          })}
        </Grid>
      }/> */}

      { !demo_tenant_enabled && !props.connectors.hasOwnProperty('items') && <Accordion header="Data Connectors" defaultExpanded body={
        <Grid container>
            {props.connectors.map((connector, i) => {
              connector.node.description = "Version 1.0.0"
              let imagePath = Connect.setImagePath(connector.node.title)
              return (
                <Grid item xl={2} lg={2} md={4} sm={5} xs={5}>
                  <Box onClick={(event)=>{window.location.href = window.location.href + '/' + connector.node.id}} style={{cursor:'pointer'}}>
                    <Card
                      variant="outlined"
                      id={connector.node.id} 
                      header={<img src={imagePath} height="75px" width="150px" style={(imagePath.includes('/Zuora-logo.png') ? zuoraImageStyling : {})}/>}
                      body={<div><div style={{height:'39.094px', overflow:'hidden', textOverflow:'ellipsis'}}><h3>{connector.node.title}</h3></div>{connector.node.description}</div>}
                    />
                  </Box>
                </Grid>
              )
            })}
        </Grid>
      }/>}

      {demo_tenant_enabled && <Accordion header="Billing Connectors" defaultExpanded body={
        <Grid container>
          <Grid item xl={2} lg={2} md={4} sm={5} xs={5}>
            <Box onClick={(event)=>{window.location.href = window.location.href.slice(0, window.location.href.lastIndexOf('/')) + '/sfdc'}} style={{cursor:'pointer'}}>
              <Card
                variant="outlined"
                id={"12"} 
                header={<img src={Connect.setImagePath("Salesforce")} height="75px" width="150px" style={(Connect.setImagePath("Salesforce").includes('/Zuora-logo.png') ? zuoraImageStyling : {})}/>}
                body={<div><div style={{height:'39.094px', overflow:'hidden', textOverflow:'ellipsis'}}><h3>{"Salesforce Connector for CRM"}</h3></div><Chip label={"New"} state={'default'} /></div>}
              />
            </Box>
          </Grid>
          <Grid item xl={2} lg={2} md={4} sm={5} xs={5}>
            <Box onClick={(event)=>{window.location.href = window.location.href.slice(0, window.location.href.lastIndexOf('/')) + '/sfdc'}} style={{cursor:'pointer'}}>
              <Card
                variant="outlined"
                id={"12"} 
                header={<img src={Connect.setImagePath("Zuora")} height="75px" width="150px" style={(Connect.setImagePath("Zuora").includes('/Zuora-logo.png') ? zuoraImageStyling : {})}/>}
                body={<div><div style={{height:'39.094px', overflow:'hidden', textOverflow:'ellipsis'}}><h3>{"Zuora CPQ for Hubspot CRM"}</h3></div><Chip
                label="Beta"
                state="info"
              /></div>}
              />
            </Box>
          </Grid>
          <Grid item xl={2} lg={2} md={4} sm={5} xs={5}>
            <Box onClick={(event)=>{window.location.href = window.location.href.slice(0, window.location.href.lastIndexOf('/')) + '/sfdc'}} style={{cursor:'pointer'}}>
              <Card
                variant="outlined"
                id={"12"} 
                header={<img src={Connect.setImagePath("NetSuite")} height="75px" width="150px" style={(Connect.setImagePath("NetSuite").includes('/Zuora-logo.png') ? zuoraImageStyling : {})}/>}
                body={<div><div style={{height:'39.094px', overflow:'hidden', textOverflow:'ellipsis'}}><h3>{"Netsuite ERP"}</h3></div><Chip
                label="Beta"
                state="info"
              /></div>}
              />
            </Box>
          </Grid>
        </Grid>
      }/>}

      {demo_tenant_enabled && <Accordion header="Appstore Connectors" defaultExpanded body={
        <Grid container>
          <Grid item xl={2} lg={2} md={4} sm={5} xs={5}>
            <Box onClick={(event)=>{window.location.href = window.location.href.slice(0, window.location.href.lastIndexOf('/')) + '/sfdc'}} style={{cursor:'pointer'}}>
              <Card
                variant="outlined"
                id={"12"} 
                header={<img src={Connect.setImagePath("Zuora")} height="75px" width="150px" style={(Connect.setImagePath("Zuora").includes('/Zuora-logo.png') ? zuoraImageStyling : {})}/>}
                body={<div><div style={{height:'39.094px', overflow:'hidden', textOverflow:'ellipsis'}}><h3>{"Apple App Store"}</h3></div><Chip
                label="Beta"
                state="info"
              /></div>}
              />
            </Box>
          </Grid>
          <Grid item xl={2} lg={2} md={4} sm={5} xs={5}>
            <Box onClick={(event)=>{window.location.href = window.location.href.slice(0, window.location.href.lastIndexOf('/')) + '/sfdc'}} style={{cursor:'pointer'}}>
              <Card
                variant="outlined"
                id={"12"} 
                header={<img src={Connect.setImagePath("Zuora")} height="75px" width="150px" style={(Connect.setImagePath("Zuora").includes('/Zuora-logo.png') ? zuoraImageStyling : {})}/>}
                body={<div><div style={{height:'39.094px', overflow:'hidden', textOverflow:'ellipsis'}}><h3>{"Google Play Store"}</h3></div><Chip
                label="Beta"
                state="info"
              /></div>}
              />
            </Box>
          </Grid>
        </Grid>
      }/>}

      {demo_tenant_enabled && <Accordion header="Tax" defaultExpanded body={
        <Grid container>
          <Grid item xl={2} lg={2} md={4} sm={5} xs={5}>
            <Box onClick={(event)=>{window.location.href = window.location.href.slice(0, window.location.href.lastIndexOf('/')) + '/sfdc'}} style={{cursor:'pointer'}}>
              <Card
                variant="outlined"
                id={"12"} 
                header={<img src={Connect.setImagePath("Zuora")} height="75px" width="150px" style={(Connect.setImagePath("Zuora").includes('/Zuora-logo.png') ? zuoraImageStyling : {})}/>}
                body={<div><div style={{height:'39.094px', overflow:'hidden', textOverflow:'ellipsis'}}><h3>{"Avalara AvaTax for Communications"}</h3></div><Chip
                label="Beta"
                state="info"
              /></div>}
              />
            </Box>
          </Grid>
          <Grid item xl={2} lg={2} md={4} sm={5} xs={5}>
            <Box onClick={(event)=>{window.location.href = window.location.href.slice(0, window.location.href.lastIndexOf('/')) + '/sfdc'}} style={{cursor:'pointer'}}>
              <Card
                variant="outlined"
                id={"12"} 
                header={<img src={Connect.setImagePath("Zuora")} height="75px" width="150px" style={(Connect.setImagePath("Zuora").includes('/Zuora-logo.png') ? zuoraImageStyling : {})}/>}
                body={<div><div style={{height:'39.094px', overflow:'hidden', textOverflow:'ellipsis'}}><h3>{"Vertex Advantage"}</h3></div><Chip
                label="Beta"
                state="info"
              /></div>}
              />
            </Box>
          </Grid>
        </Grid>
      }/>}
    </AutoThemeProvider>
  );
}

export default Marketplace;
