import { AutoThemeProvider, Tab, Tabs } from '@platform-ui/design-system';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import GenericTable from '../Tables/components/GenericTable';
import { HISTORIES_TABLE_PROPS, ORGANIZATIONS_TABLE_PROPS } from '../Tables/constants';
import * as ReactDOM from 'react-dom/client';

const OrganizationsView = (props) => {

    const [tabs, setTabs] = useState(0);

    const root = ReactDOM.createRoot(document.getElementById("pageHeader"));
    root.render(
        <AutoThemeProvider>
        <h2>{"Organizations"}</h2>
        <Tabs
                    a11yLabel="tab"
                    value={tabs}
                    dsOnChange={(_, newIndex) => {
                        if (newIndex === 0) {
                            setTabs(0);
                        }
                        if (newIndex === 1) {
                            setTabs(1);
                        }
                        }}
            
                    e2e="organizations-tabs"
                    >
                    <Tab
                        id="organizations-tab"
                        e2e="organizations"
                        label="Organizations"
                    />
                    <Tab
                        id="history-tab"
                        e2e="history"
                        label="History"
                    />
                    
                </Tabs>
                </AutoThemeProvider>
      );

    return (
        <AutoThemeProvider>
            {tabs === 0 && <GenericTable {...ORGANIZATIONS_TABLE_PROPS} {...props.tables.organizations_table}/>}
            {tabs === 1 && <GenericTable {...HISTORIES_TABLE_PROPS} {...props.tables.histories_table}/>}
        </AutoThemeProvider>
    )
}

OrganizationsView.propTypes = {
    tables: PropTypes.object
}
export default OrganizationsView;