import { reducer } from './reducer';
import * as IntegrationAppFormActionTypes from './actionTypes';
import { createStore } from '../../Store';

const IntegrationAppFormContextProvider = createStore(reducer);

export { IntegrationAppForm } from  './components/IntegrationAppForm';
export { initialState } from './state';
export { IntegrationAppFormContextProvider };
export { IntegrationAppFormActionTypes };
export { actionMap } from './actionMap';
