import { Accordion, AutoThemeProvider, Button, Card, designTokens, Grid, Toggle, Typography } from "@platform-ui/design-system"
import {ConfigWizard} from "./ConfigWizard";
import React, { useState } from "react"
import WorkflowButton from "./WorkflowButton";
import Connect from "../Connect/Connect";
import { Box, CircularProgress, Divider } from "@mui/material";

function ConnectorDetails(props: any) {
  const [configurationLink, setConfigurationLink] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [enabled, setEnabled] = useState(props.connector.node.enabled);
  const connect: Connect = (window as any).connect;

  function createConfigurationPopup(solutionId: string, solutionName: string) {
    setIsLoading(true);
    let csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    fetch(``, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      },
      body: JSON.stringify({solution_id: solutionId, name: solutionName})
    }).then(function(res){
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      return res.json(); 
    }).then(response => {
      setConfigurationLink(response.url);
      
    }).finally(() => setIsLoading(false));
  }

  function toggleInstanceEnabled(enabled: boolean) {
    let csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    fetch(window.location.href + '/update', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      },
      body: JSON.stringify({enabled: enabled})
    }).then(function(res){
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      return res.json(); 
    }).then(response => {
      setEnabled(enabled);
    }).catch((error) => {
      Connect.log(error);
    });
  }

  let description = '';
  let name = '';
  let instanceName = '';
  let title = '';
  if (props.connector.node.solution) {
    description = props.connector.node.solution.description ? props.connector.node.solution.description : "";
    name = props.connector.node.solution.title;
    instanceName = props.connector.node.name;
    title = instanceName;
  } else {
    description = props.connector.node.description ? props.connector.node.description : "";
    name = props.connector.node.title;
    title = name;
  }
  let imagePath = Connect.setImagePath(name);
  let zuoraImageStyling = {'marginTop':'125px','marginBottom':'125px'};

  return (
    <AutoThemeProvider>
      {!(configurationLink) && 
        <Grid container>
          <Grid item xs={12}>
            <Card 
              id={name}
              body={
                <Grid container>
                  <Grid item xs={6}>
                    <div style={{float:'left', height:'inherit'}}>
                      <img src={imagePath} height="50%" width="100%" style={(imagePath.includes('/Zuora-logo.png') ? zuoraImageStyling : {})}/>
                    </div>
                  </Grid>
                  <Divider orientation="vertical" flexItem style={{marginTop:'16px', marginRight:'32px', marginLeft:'32px'}}/>
                  <Grid item xs={5}>
                    <h3>{title}</h3>
                    <Typography variant={"body1"} body={description} />
                      <Divider variant="middle" style={{marginTop:'20px', marginBottom:'20px', marginLeft:'0'}} />
                      <Grid container>
                        <Grid item sx={{marginRight: '24px'}}>
                          <Box sx={{m: 1, position: 'relative'}}>
                            <Button dsOnClick={() => createConfigurationPopup(props.connector.node.id, connect.tenant.tenant_id)} disabled={isLoading} children="Configure" />
                            {
                              isLoading &&
                              <CircularProgress
                                size={24}
                                sx={{
                                  color: designTokens.colors.blue500,
                                  position: 'absolute',
                                  top: '50%',
                                  left: '50%',
                                  marginTop: '-12px',
                                  marginLeft: '-12px'
                                }}
                              />
                            }
                          </Box>
                        </Grid>
                        {enabled !== undefined && <Grid item><Toggle label={"Enable"} dsOnChange={() => {toggleInstanceEnabled(!enabled)}} checked={enabled}/></Grid>}
                      </Grid>
                  </Grid>
                </Grid>
              }
            />
          </Grid>
          <Grid item xs={12}>
            {props.connector.node.workflows !== undefined && <Accordion header="Workflows" body={
              <Grid container>
                {props.connector.node.workflows.edges.map((element, i) => {
                  if (element.node.triggerUrl) {
                    return (
                      <Grid item><WorkflowButton workflow={element.node}/></Grid>
                    )
                  }
                })}
              </Grid>
            } />}
          </Grid>
        </Grid>
      }
      {configurationLink && !isLoading && <ConfigWizard src={configurationLink} />}
    </AutoThemeProvider>
  )
}

export default ConnectorDetails