import React from 'react';
import { Card, Grid, Spinner } from "@platform-ui/design-system"
import Connect from '../Connect/Connect';

export class ConfigWizard extends React.PureComponent {
    state = {
      ready: false,
    };

    constructor(props) {
      super(props);
      this.iframe = React.createRef();
    }

    componentDidMount() {
      window.addEventListener("message", this.handleIframeEvents);
    }

    componentWillUnmount() {
      window.removeEventListener("message", this.handleIframeEvents);
    }

    handleIframeEvents = (e) => {
      // console.log(`${e.data.type} event received`);
      // Here we should handle all event types
      if (e.data.type === 'tray.configPopup.error') {
        alert(`Error: ${e.data.err}`);
      }
      if (e.data.type === 'tray.configPopup.cancel') {
        location.reload();
      }
      if (e.data.type === 'tray.configPopup.ready') {
        this.setState({ ready: true });
      }
      if (e.data.type === 'tray.configPopup.finish') {
        // NOTE(Xander): We make a call to the backend to enable the solution instance on successful setup
        let csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
        fetch(window.location.href + '/' + e.data.data.solutionInstanceId + '/update', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrf
          },
          body: JSON.stringify({enabled: true})
        }).then(function(res){
          if (!res.ok) {
            throw new Error(res.statusText)
          }
          return res.json(); 
        }).then(response => {
          setEnabled(enabled)
        }).catch((error) => {
          Connect.log(error);
        })

        
        let path = ''
        if (localStorage.getItem('data-connector-hub-url')) {
          path = localStorage.getItem('data-connector-hub-url');
        } else {
          // NOTE(Xander): This split is performed on the connectors field so that it works in both billing and connect proper
          let fullPath = window.location.pathname.split('connectors/')
          path = fullPath[0] + 'connectors/' + fullPath[1].split('/')[0]
          if (window.location.href.includes('services/cnnct-no-layout')) {
            path = path.replace('services/connect', 'services/cnnct-no-layout')
          }
        }
        window.location.replace(path)
      }
    };

    render() {
      const styles = {
        iframe: {
          width: '100%',
          height: '100%',
          minHeight: '500px',
          border: '0px',
          borderRadius: '4px',
          boxSizing: 'border-box',
        },
        loadingScreen: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          position: 'absolute',
          background: 'white',
          border: '0px',
          borderRadius: '4px',
          display: 'flex',
          alignItems: 'center',
        },
        spinnerContainer: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minWidth: '-webkit-fill-available'
        }
      };

      return (
        <Card id={'configurator'} body={
          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <iframe
                ref={this.iframe}
                style={styles.iframe}
                src={this.props.src}
                title="Solution Instance Configurator"
              />
              {!this.state.ready && <div style={styles.loadingScreen}><div style={styles.spinnerContainer}><Spinner /></div></div>}
            </Grid>
          </Grid>}
        />
      )
    }
}

export default ConfigWizard;
