import { Alert, AutoThemeProvider, Table, Spinner } from '@platform-ui/design-system';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import ReactHtmlParser from 'react-html-parser';

const IdentitiesTable = (props) => {
    // Table Consts
    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(true);
    const [fetching, setFetching] = useState(false);
    const [rowActions, setRowActions] = useState([]);
    const [reloadTable, setReloadTable] = useState(false);
    const [noRows, setNoRows] = useState(false);

    // Alert Consts
    const [isAlert, setIsAlert] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertHeader, setAlertHeader] = useState("");
    const [alertBody, setAlertBody] = useState("");

    // NOTE(Xander): In order to use older Connect modals with the new table, we need to replicate Rails-Ajax functionality
    // This method ensures that all links generated by the table will render their modal on the same page without redirecting
    const ajaxCall = () => {
        $(".MuiList-root a").attr("data-remote",true);
    }

    // NOTE(Michael): this is use to detech when the user is finished typing in the search bar
    const [searchValue, setSearchValue] = useState('')
    useEffect(() => {
        const timer = setTimeout(() => {
            fetchFromBackend(searchValue)
        }, 500)
        if (searchValue.length === 0 && rows.length > 0) {
            setNoRows(false);
        }
        return () => clearTimeout(timer)
    }, [searchValue])

    useEffect(() => {
      getIdentities(null);
      }, [reloadTable]);

    const getIdentities = async (event) => {
      setLoading(true);
      const URL = props.backend_uri;
      try {
          const options = {
              headers: new Headers({
                  'Accept': 'application/json',
                  'Content-type': 'application/json'
              })
          };
          const response = await window.fetch(props.backend_uri, options);
          if (!response.ok) throw Error(response.statusText);
          const data = await response.json();
          setRows(data.rows);
          setRowActions(
              [
                  {
                      icon: 'more_horiz',
                      onClick: getRowActions,
                      listChildren: []
                  }
              ]
          );
          if (data.rows.length === 0) {
            setNoRows(true);
          } else {
            setNoRows(false);
          }
          setLoading(false);
        } catch (error) {
          setRows([]);
          setRowActions([]);
          setLoading(false);
          setNoRows(true);
      }
    }

    const getRowActions = async (event) => {
        try {
            let csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
            const options = {
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'X-CSRF-Token': csrf
                })
            };
            const response = await window.fetch('/admin/users/identities/' + event.row.id + '/actions', options);
            if (!response.ok) throw Error(response.statusText);
            const actions = await response.json();
            setRowActions(regenerateRowActions(actions));
        } catch (error) {
            console.error(error)
        }
    }

    function regenerateRowActions(actions) {
        return [
            {
                dsGetActionData: (row) => {
                    var listChildren = []
                    row.actions = actions
                    if (row.actions != null) {
                        var template_link = {}
                        row.actions.forEach((element) => {
                            template_link = {
                                label: element.label
                            };
                            if (element.ajaxCall) {
                                template_link.onClick = ajaxCall
                            }
                            if (element.method === "get") {
                                template_link.href = element.path
                            } else {
                                template_link.onClick = () => {
                                    apiCall(element);
                                }
                            }
                            if (element.disabled) {
                                template_link.disabled = true
                            }
                            listChildren.push(template_link);
                        });
                        return {
                            icon: 'more_horiz',
                            listChildren: listChildren,
                            onClick: getRowActions
                        };
                    }
                }
            }
        ];
    }

    const apiCall = element => {
        try {
            // NOTE(Xander): This query grabs the rails CSRF token for requests
            let csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
            fetch(element.path, {
                method: element.method,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': csrf
                }
            }).then((response) => {
                setReloadTable(!reloadTable);
                if (response.status == 200 || response.ok == true) {
                    setAlertHeader("Successfully Performed Action");
                    // setAlertBody("Aren't you a cool guy!");
                    setAlertSeverity("success");
                } else {
                    setAlertHeader(`${response.status}: ${response.statusText}`);
                    setAlertBody("Please Try Again Later");
                    setAlertSeverity("error");
                }
                setIsAlert(true);
            }).catch((error) => {
                console.error(error);
            });
        } catch (error) {
            console.error(error);
        }
    }

    const fetchFromBackend = async (args) => {
        if (args.length != 0){
            setFetching(true);
            if (props.backend_uri.includes("?")){
                var url = props.backend_uri +"&";
            }else{
                var url = props.backend_uri + "?";
            }
            
            try {
                const options = {
                    headers: new Headers({
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    })
                };
                const response = await window.fetch(url+new URLSearchParams({
                    sSearch: args, iDisplayLength: 100}), options);
                if (!response.ok) throw Error(response.statusText);
                const data = await response.json();
                var ids = new Set(rows.map(d => d.id));
                var merged = [...rows, ...data.rows.filter(d => !ids.has(d.id))];
                if (data.rows.length === 0) {
                    setNoRows(true);
                }
                setRows(merged);
                setFetching(false);
            } catch (error) {
                console.error(error)
                setFetching(false);
            }
        }
    }

    return (
        <AutoThemeProvider>
            <Alert
                body={alertBody}
                dismissible
                dsOnClose={() => {
                    setIsAlert(false);
                }}
                e2e="identityAlert"
                header={alertHeader}
                open={isAlert}
                severity={alertSeverity}
            />
            <Table
                loading={loading}
                columns={[
                    {
                        field: 'id',
                        label: 'Id',
                        labelTooltip: 'A unique identifier'
                    },
                    {
                      field: 'provider',
                      label: 'Provider',
                      labelTooltip: 'A unique identifier'
                    },
                    {
                      field: 'uid',
                      label: 'uid',
                      labelTooltip: 'A unique identifier'
                    },
                    {
                      field: 'name',
                      label: 'Name',
                      labelTooltip: 'A unique identifier'
                    },
                    {
                      field: 'email',
                      label: 'Email',
                      labelTooltip: 'A unique identifier'
                    },
                    {
                      field: 'nickname',
                      label: 'Nickname',
                      labelTooltip: 'A unique identifier'
                    },
                    {
                      field: 'hostname',
                      label: 'Hostname',
                      labelTooltip: 'A unique identifier'
                    },
                    {
                      field: 'phone',
                      label: 'Phone',
                      labelTooltip: 'A unique identifier'
                    },
                    {
                      field: 'info',
                      label: 'Info',
                      labelTooltip: 'A unique identifier'
                    },
                    {
                      field: 'tenant_id',
                      label: 'Tenant id',
                      labelTooltip: 'A unique identifier',
                      dsRenderCell: ({ value }) => {
                          return <div> {ReactHtmlParser (value)} </div>
                      }
                    },
                    {
                        field: 'created_at',
                        label: 'Created at'
                    },
                    {
                        field: 'updated_at',
                        label: 'Updated at'      
                    },
                    {
                        field: 'users_email',
                        label: 'Users email'
                    },
                    {
                        field: 'users_username',
                        label: 'Users username',
                        dsRenderCell: ({ value }) => {
                            return <div> {ReactHtmlParser (value)} </div>
                        }
                    }
                  ]}
                e2e={props.table_name}
                rows={rows}
                uniqueKey={props.table_name}
                searchable
                rowsPerPage={20}
                rowActions={rowActions}
                tableActions={[
                    {
                        icon: "sync",
                        label: "Refresh Identities",
                        onClick: getIdentities,
                    }
                ]}
                orderable={true}
                showNoRowsMessage={noRows}
                dsOnSearchChange={(args) => setSearchValue(args)}
            >
            </Table>
            <p style={fetching ? {} : { display: 'none' }}>
                  
                <Spinner variant="linear"/>
                <p>
                    Fetching from the database
                </p>
            </p>
        </AutoThemeProvider>
    );
};

IdentitiesTable.propTypes = {
  rows: PropTypes.array, // this is passed from the Rails view
};

export default IdentitiesTable;