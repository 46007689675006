import { AutoThemeProvider, Tab, Tabs } from '@platform-ui/design-system';
import React, { useState } from 'react';
import GenericTable from '../../Tables/components/GenericTable';
import { INTEGRATION_APPS_TABLE_PROPS, HISTORIES_TABLE_PROPS } from '../../Tables/constants';
import * as ReactDOM from 'react-dom/client';


function AdminIntegrationAppsView(props: any) {
  const [tabs, setTabs] = useState(0);

  const root = ReactDOM.createRoot(document.getElementById("pageHeader"));
  root.render(
    <AutoThemeProvider>
      <h2>{"Integration Apps"}</h2>
      <Tabs
        a11yLabel="tab"
        value={tabs}
        dsOnChange={(_, newIndex) => {
          if (newIndex === 0) {
            setTabs(0);
          }
          if (newIndex === 1) {
            setTabs(1);
          }
        }}

        e2e="integration-apps-tabs"
      >
        <Tab
          id="integration-apps-tab"
          e2e="integration-apps"
          label="integration-apps"
          aria-controls={""}
        />
        <Tab
          id="history-tab"
          e2e="history"
          label="History"
          aria-controls={""}
        />

      </Tabs>
    </AutoThemeProvider>
  );

  return (
    <AutoThemeProvider>
      {tabs === 0 && <GenericTable {...INTEGRATION_APPS_TABLE_PROPS} {...props.tables.integration_apps_table} />}
      {tabs === 1 && <GenericTable {...HISTORIES_TABLE_PROPS} {...props.tables.histories_table} />}
    </AutoThemeProvider>
  )
}

export default AdminIntegrationAppsView