import { Box, Chip } from "@mui/material";
import { AutoThemeProvider, Button, Card, Dropdown, DropdownItem, Grid, Link, Modal, ModalActions, ModalTitle, Select, SelectItem, Typography } from "@platform-ui/design-system";
import React, { useState } from "react";
import Connect from "../Connect/Connect";
import $ from "jquery";
import Icon from "./Icon";

function GridTile(props: any) {
  const [isVisible, setIsVisible] = useState(true)
  const [dropdownAnchor, setDropdownAnchor] = useState(null);
  const [clickable, setClickable] = useState(true)
  const [enabled, setEnabled] = useState(true)
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [externalId, setExternalId] = useState('');

  let header = props.header
  let subHeader = props.subHeader
  let path = props.path
  let status = props.status
  let surtitle = props.surtitle
  let icon = props.icon
  let version = props.version
  let imagePath = Connect.setImagePath(props.header)
  let zuoraImageStyling = {'paddingTop':'20px','paddingBottom':'25px'}

  if (window.location.href.includes('services/cnnct-no-layout')) {
    path = path.replace('services/connect', 'services/cnnct-no-layout')
  }

  const handleOpenDropdown = (event:any) => {
    setDropdownAnchor(event.currentTarget);
  }

  const handleCloseDropdown = () => {
    setDropdownAnchor(null);
  }

  const clickTile = async () => {
    if (path?.includes('?multiple=true')) {
      try {
        const csrf = document.querySelector('meta[name=\'csrf-token\']').getAttribute('content');
        const options = {
          headers: new Headers({
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'X-CSRF-Token': csrf
          })
        };
        const prefix = !window.location.host.includes('connect') && !window.location.host.includes('localhost') ? '/services/connect' : '';
        const [_, tenantId, appsId] = path.replace('?multiple=true','').split('/integration_apps/');
        const response = await fetch(`${prefix}/integration_apps_settings/${appsId}/${tenantId}.json?multiple=true`, options);
        if (!response.ok) throw Error('Error fetching settings');
        const data = await response.json();
        if (data.length > 0) {
          setModalData(data.map(d => d.external_id).filter(d => d));
          setOpenModal(true);
        } else {
          path != '' ? window.location.href = path.replace('?multiple=true','') : '';
        }
      } catch (err) {
        path != '' ? window.location.href = path : '';
      }
    } else {
      path != '' ? window.location.href = path : '';
    }
  }

  // NOTE(Xander): In order to use older Connect modals with the new table, we need to replicate Rails-Ajax functionality
  // This method ensures that all links generated by the table will render their modal on the same page without redirecting
  const ajaxCall = () => {
    $(".MuiList-root a").attr("data-remote",true);
  }

  const iconStyle: React.CSSProperties = {
    objectFit: 'contain'
  }

  function handleAction(action) {
    if (action.method === "get") {
      window.location.href = action.path
    } else {
      props.apiCall(action)
    }
  }

  function chipSettings(environment) {
    switch (environment) {
      case "PROD":
        return {backgroundColor: '#33495C', color: '#FFFFFF', marginRight: '4px'};
      case "SBX":
        return {backgroundColor: '#B36526', color: '#FFFFFF', marginRight: '4px'};
      case "CSBX":
        return {backgroundColor: '#6F4DCD', color: '#FFFFFF', marginRight: '4px'};
/*
      case "CRM":
        return {backgroundColor: '#F6C7EF', marginRight: '4px'};
      case "AppStore":
        return {backgroundColor: '#D2DEE7', marginRight: '4px'};
      case "CPQ":
        return {backgroundColor: '#916FEF', marginRight: '4px'};
      case "ERP":
        return {backgroundColor: '#916FEF', marginRight: '4px'};
      case "Data Warehouse":
        return {backgroundColor: '#B0CEEF', marginRight: '4px'};
      case "Payment Gateway":
        return {backgroundColor: '#B8EFE6', marginRight: '4px'};
      case "Coming Soon":
        return {backgroundColor: '#B8EFE6', marginRight: '4px'};
      case "Zuora Billing":
        return {backgroundColor: '#00D2B9', marginRight: '4px'};
      case "Zuora Revenue":
        return {backgroundColor: '#D2DEE7', marginRight: '4px'};
      case "Zuora Payments":
        return {backgroundColor: '#F6C7EF', marginRight: '4px'};
      case "Zephyr":
        return {backgroundColor: '#F6C7EF', marginRight: '4px'};
*/
      default:
        return {backgroundColor: '#0000001F', marginRight: '4px'};
    }
  }

  function isUnacceptableStatus(status) {
    const acceptableStatuses = ['Public', 'Active']
    if (acceptableStatuses.includes(status)) {
      return false
    }
    else {
      return true
    }
  }

  return (
    <AutoThemeProvider>
    {
      path?.includes('?multiple=true') &&
      <Modal
        id='multiple-instances-modal'
        open={openModal}
        dsOnClose={() => setOpenModal(false)}
        header={
          <ModalTitle>Select/Create an Instance</ModalTitle>
        }
        body={
          <Select label='Instances' value={externalId} dsOnChange={(e) => setExternalId(e.target.value as any)}>
            {
              modalData.map(d => <SelectItem value={d}>{d}</SelectItem>)
            }
          </Select>
        }
        footer={
          <ModalActions>
            <Button
              tooltip='Create a new instance'
              dsOnClick={() => {
                setOpenModal(false);
                window.location.href = path !== '' ? path.replace('multiple=true', 'new=true') : '';
              }}
            >
              Create
            </Button>
            <Button
              tooltip='Select an instance'
              disabled={externalId === ''}
              dsOnClick={() => {
                setOpenModal(false);
                window.location.href = path !== '' ? path.replace('multiple=true', `external_id=${externalId}`) : '';
              }}
            >
              Select
            </Button>
          </ModalActions>
        }
      />
    }
      {isVisible && <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
      {props.integrationView && <Card
          variant={props.useElevationTiles ? 'elevation' : 'outlined'}
          id={header}
          header={
            <Box
            onClick={clickTile}
            sx={{display: 'flex', gridAutoRows: '1fr', gap: '16px', minHeight: '100px', maxHeight: '120px', '&:hover':{cursor:'pointer'}}}>
              <Box>
                <img height='72px' width='72px' src={icon} style={iconStyle}></img>
              </Box>
              <Box sx={{display: 'flex', gridAutoRows: '1fr', flexDirection: 'column', justifyContent: 'space-between'}}>
                <Typography variant='subtitle1' maxLine={1}>
                  {header} {isUnacceptableStatus(status) && <Icon body='warning' color='error' tooltip={status} sx={{paddingTop: '3px'}} />}
                </Typography>
                <Typography variant='body1' maxLine={2} sx={{ overflow: 'hidden', textOverflow: 'ellipsis'}} truncated>
                  {surtitle}
                </Typography>
                <Box>
                  {typeof(subHeader) === 'string' && <Chip label={subHeader} sx={chipSettings(subHeader)} /> }
                  {typeof(subHeader) === 'object' && subHeader.map(chip => {
                    return (<Chip label={chip} sx={chipSettings(chip)} />)
                  })}
                </Box>
              </Box>
            </Box>
          }
        />}
        {props.connectionsView && <Card
          variant="outlined"
          id={header}
          header={
            <Box onClick={(event)=>{path != '' ? window.location.href = path : ''}} style={path != '' ? {cursor:'pointer'} : {}}>
              <Grid container>
                <Grid item >
                  <img height='72px' width='72px' src={icon} style={iconStyle}></img>
                </Grid>
                <Grid container item xl={3} lg={3} md={4} sm={6} xs={12} >
                  <Grid item sx={{paddingRight: '16px', paddingTop: '6px !important'}}>
                    {typeof(subHeader) === 'string' && <Chip label={subHeader} sx={chipSettings(subHeader)} /> }
                    {typeof(subHeader) === 'object' && subHeader.map(chip => {
                      return (<Chip label={chip} sx={chipSettings(chip)} />)
                    })}
                  </Grid>
                  <Grid item sx={{'flex':'none', paddingTop: '0 !important'}}><Typography variant="h6">{header} {isUnacceptableStatus(status) && <Icon body='warning' color='error' tooltip={status} sx={{paddingTop: '3px'}} />}</Typography> </Grid>
                  <Grid item sx={{'flex':'none', paddingTop: '0 !important'}}><Typography variant='surtitle'>{surtitle}</Typography></Grid>
                </Grid>
              </Grid>
            </Box>
          }
          headerAction={
            <div>
              <Button icon={'more_vert'} dsOnClick={handleOpenDropdown}/>
              <Dropdown
                a11yId={'dropDown'}
                anchorEl={dropdownAnchor}
                dsOnClose={handleCloseDropdown}
                open={Boolean(dropdownAnchor)}
              >
                {props.actions.map((action, i) => {
                  return <DropdownItem dsOnClick={handleCloseDropdown}><Link href={action.ajaxCall ? action.path : ''} disabled={action.disabled} dsOnClick={() => {action.ajaxCall ? ajaxCall : props.apiCall(action)}}>{action.label}</Link></DropdownItem>
                })}
              </Dropdown>
            </div>
          }
        />}
        {!props.connectionsView && !props.integrationView && <Card
          variant="outlined"
          id={header}
          header={<Box onClick={(event)=>{path != '' ? window.location.href = path : ''}} style={{cursor:'pointer'}}><img src={imagePath} height="75px" width="150px" style={(imagePath.includes('/Zuora-logo.png') ? zuoraImageStyling : {})}/></Box>}
          body={<Box onClick={(event)=>{path != '' ? window.location.href = path : ''}} style={{cursor:'pointer'}}><div><div style={{height:'39.094px', overflow:'hidden', textOverflow:'ellipsis'}}><h3>{header}</h3></div>{subHeader}</div></Box>}
          headerAction={
            <div>
              <Button icon={'menu'} dsOnClick={handleOpenDropdown}/>
              <Dropdown
                a11yId={'dropDown'}
                anchorEl={dropdownAnchor}
                dsOnClose={handleCloseDropdown}
                open={Boolean(dropdownAnchor)}
              >
                {clickable && <DropdownItem dsOnClick={(event)=>{path != '' ? window.location.href = path : ''}}>
                  Show
                </DropdownItem>}
                {props.actions.map((action, i) => {
                  return <DropdownItem dsOnClick={() => {
                    if (action.ajaxCall) {
                      ajaxCall
                    }
                    handleAction(action)
                  }} >{action.label}</DropdownItem>
                })}
              </Dropdown>
            </div>
          }
        />}
      </Grid>}
    </AutoThemeProvider>
  )
}

export default GridTile;