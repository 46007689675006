import { AutoThemeProvider, Tab, Tabs } from '@platform-ui/design-system';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import GenericTable from '../Tables/components/GenericTable';
import { HISTORIES_TABLE_PROPS, USERS_TABLE_PROPS } from '../Tables/constants';
import * as ReactDOM from 'react-dom/client';

const UsersView = (props) => {

    const [tabs, setTabs] = useState(0);

    const root = ReactDOM.createRoot(document.getElementById("pageHeader"));
    root.render(
        <AutoThemeProvider>
            <h2>{"Users"}</h2>
            <Tabs
                a11yLabel="tab"
                value={tabs}
                dsOnChange={(_, newIndex) => {
                    if (newIndex === 0) {
                        setTabs(0);
                    }
                    if (newIndex === 1) {
                        setTabs(1);
                    }
                }}

                e2e="tenant-tabs"
            >
                <Tab
                    id="users-tab-1"
                    e2e="Users"
                    label="Users"
                />
                <Tab
                    id="users-tab-2"
                    e2e="history"
                    label="History"

                />

            </Tabs>
        </AutoThemeProvider>
    );

    return (
        <AutoThemeProvider>
            {tabs === 0 && <GenericTable {...USERS_TABLE_PROPS} {...props.tables.users_table} />}
            {tabs === 1 && <GenericTable {...HISTORIES_TABLE_PROPS} {...props.tables.histories_table} />}
        </AutoThemeProvider>
    );
}

UsersView.propTypes = {
    users_table: PropTypes.object
}

export default UsersView;