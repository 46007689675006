import { AutoThemeProvider, Grid, Tab, Tabs, Typography } from '@platform-ui/design-system';
import React, { useState } from 'react';
import GridView from '../GridView/GridView';
import { INTEGRATION_APPS_FILTERS, INTEGRATION_APPS_FILTER_KEYS, INTEGRATION_APPS_FEATURED_QUERY_PARAMS } from '../GridView/constants';
import $ from 'jquery';
import { useFlipperHook } from '../Tables/helpers';
import {Box} from "@mui/material";
import { AmplitudeEvents, useAmplitude } from '../Amplitude/hooks/useAmplitude';

function IntegrationAppsView(props: any) {
  // NOTE(Xander): In Billing, we want to replace the Marketplace Header in the sidebar with Integration Hub, but we can't do that in time for ZKO, so we hack
  // TODO(Xander): Remove this after we make an MR for Billing to change the Marketplace header
  const [ihToMarketplace, loading] = useFlipperHook('ih_to_marketplace');
  if (ihToMarketplace) {
    $(document).ready(function() {
      $("button#Marketplace-header div.MuiAccordionSummary-content span.MuiTypography-root span").text('Integration Hub');
    });
  }

  const [tabs, setTabs] = useState(0);
  const readOnly = props.readOnly ?? false;
  const { logEvent } = useAmplitude();

  logEvent(AmplitudeEvents.PAGE_VIEWED, {object: 'integrationhub', view: 'list', screenHeight: window.innerHeight, screenWidth: window.innerWidth})

  return (
    <AutoThemeProvider>
      <h2>Integration Apps</h2>
      <Box paddingBottom={readOnly && 1.5}>
        <Typography variant='surtitle' body='Integrate 3rd party applications with your Zuora products.' />
      </Box>
      {
        !readOnly &&
          <Tabs
              a11yLabel="tab"
              value={tabs}
              dsOnChange={
                (_, newIndex) => {
                  setTabs(newIndex)
                }
              }
              e2e="connectors-tabs"
          >
              <Tab
                  id="all-connectors-tab"
                  e2e="allConnectors"
                  label="All Connectors"
                  aria-controls={""}
              />
              <Tab
                  id="my-connectors-tab"
                  e2e="myConnectors"
                  label="My Connectors"
                  aria-controls={""}
              />
          </Tabs>
      }

      {(readOnly || tabs === 0) && <Grid container>
        <Grid item xs={12}>
          <GridView display={3} gridTitle='Featured Connectors' integrationView={true} queryParams={INTEGRATION_APPS_FEATURED_QUERY_PARAMS} keyboardNavDisabled = {true} {...props.integrationApps} />
        </Grid>
        <Grid item xs={12}>
          <GridView display={24} filters={INTEGRATION_APPS_FILTERS} filterKeys={INTEGRATION_APPS_FILTER_KEYS} gridTitle='Zuora Connectors' integrationView={true} searchEnabled={true} {...props.integrationApps} />
        </Grid>
      </Grid>}
      {!readOnly && tabs === 1 &&
        <GridView display={24} filters={INTEGRATION_APPS_FILTERS} filterKeys={INTEGRATION_APPS_FILTER_KEYS} gridTitle='My Connectors' integrationView={true} searchEnabled={true} {...props.integrationAppsSettings} /> 
      }
    </AutoThemeProvider>
  )
}

export default IntegrationAppsView;
